export default {
    label: {
        name: "Tên kết nối",
        connection: "Kết nối",
        typeInformation: "Kênh kết nối",
        typeInformationName: "Tên kênh kết nối",
        typeConnection: "Lĩnh vực kết nối",
        lastSynchorised: "Thời điểm đồng bộ dữ liệu gần nhất",
        listFile: "Danh sách file",
        fileName: "Tên file",
        timeUpload: "Thời điểm đẩy file",
        fileNameSharePoint: "Tên file share point",
        fileNameCloud: "Tên file cloud",
        useUri: "Sử dụng điều hướng",
        mainAccount: "Tên shop",
        shopName: "Tên shop",
        datasource: "Kho lưu trữ dữ liệu",
        tokenState: "Trạng thái token",
        sessionState: "Trạng thái session của Extension",
        apiSyncState: "Trạng thái đồng bộ qua API",
        sessionSyncState: "Trạng thái đồng bộ qua Extension",
        updatedTime: "Thời điểm cập nhật",
        username: "Tên tài khoản",
        active: "Hoạt động",
        state: "Trạng thái"
    },
    text: {
        inputTypeInformationName: "Nhập tên kênh kết nối",
        selectTypeConnection: "Chọn lĩnh vực kết nối",
        selectTypeInformation: "Chọn kênh kết nối",
        initInformation: "Khai báo thông tin",
        initInformationBonus: "Kiểm tra và xác nhận khởi tạo",
        createFinish: "Thông báo hoàn thành",
        inputInformation: "Nhập thông tin",
        initInformationSuccess: "Khai báo thông tin thành công",
        inputConnectionName: "Nhập tên kết nối",
        inputConnectionMainAccoutn: "Nhập tài khoản chính chủ (username)",
        inputShopName: "Nhập tên shop"
    },
    messages: {
        TOKEN_NOT_GET: "Chưa lấy được token",
        TOKEN_GET: "Đã lấy được token",
        TOKEN_EXPIRED: "Token hết hạn",
        TOKEN_REFRESH_ERROR: "Token làm mới bị lỗi",
        SESSION_NOT_GET: "Chưa lấy được session",
        SESSION_GET: "Đã lấy được session",
        SESSION_EXPIRED: "Session hết hạn",
        SYNC_NOT_YET: "Chưa đồng bộ",
        SYNCING: "Đang đồng bộ",
        SYNCED: "Đã đồng bộ",
        SYNC_ERROR: "Đồng bộ lỗi",
        UNKNOW: "Không xác định",
        lastSyncTime: "Thời gian đồng bộ thành công gần nhất",
        noPermissionTypeInformation: "Bạn chưa có quyền sử dụng kênh kết nối này. Vui lòng thử kênh kết nối khác hoặc liên hệ hotline \"039 289 6286\" để nâng cấp tài khoản có quyền sử dụng kênh kết nối này!"
    }
}