import { ObservableService } from "./observable.service";
import { Injectable, Inject } from "@angular/core";
import { CONSTANTS } from "./constants";
@Injectable({ providedIn: 'root'})
export class MessageCommonService {
    constructor(@Inject(ObservableService) private observableService: ObservableService) { 
        
    }

    public confirm(title:string, message: string, action:{ok?:Function, cancel?:Function}){
        this.observableService.next(CONSTANTS.OBSERVABLE.KEY_MESSAGE_COMMON,{message,title, action, type: "confirm", isShow: true})
    }

    public notify(title:string, message: string, action:{ok?:Function, cancel?:Function}, icon?:string, acceptLabel?:string, rejectLabel?:string){
        this.observableService.next(CONSTANTS.OBSERVABLE.KEY_MESSAGE_COMMON,{message,title, action, type: "notify", isShow: true, icon, acceptLabel, rejectLabel})
    }

    public success(message: string, summary?:string, timeout?:number){
        this.observableService.next(CONSTANTS.OBSERVABLE.KEY_MESSAGE_COMMON,{message, summary, type: "success", isShow: true, timeout})
    }

    public info(message: string, summary?:string, timeout?:number, data?: any){
        this.observableService.next(CONSTANTS.OBSERVABLE.KEY_MESSAGE_COMMON,{message, summary, type: "info", isShow: true, timeout, data})
    }

    public warning(message: string, summary?:string, timeout?:number){
        this.observableService.next(CONSTANTS.OBSERVABLE.KEY_MESSAGE_COMMON,{message, summary, type: "warning", isShow: true, timeout})
    }

    public error(message: string, summary?:string, timeout?:number, data?: any){
        this.observableService.next(CONSTANTS.OBSERVABLE.KEY_MESSAGE_COMMON,{message, summary, type: "error", isShow: true, timeout, data})
    }

    public onload(){
        this.observableService.next(CONSTANTS.OBSERVABLE.KEY_MESSAGE_COMMON,{type: "load", isShow: true})
    }

    public offload(){
        this.observableService.next(CONSTANTS.OBSERVABLE.KEY_MESSAGE_COMMON,{type: "load", isShow: false})
    }
}