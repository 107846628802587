import { Inject, Injectable } from "@angular/core";
import { HttpService } from "../comon/http.service";

@Injectable({ providedIn: 'root'})
export class ConnectionService {
    private prefixApi: string;
    constructor(@Inject(HttpService) private httpService:HttpService) {
        this.prefixApi = "/connect";
    }

    getListTypeConnection(callback?, errorCallback?, finallyCallback?){
        this.httpService.get(`${this.prefixApi}/typeConnection`, {}, {}, callback, errorCallback, finallyCallback);
    }

    deleteTypeInformation(typeInformationId, callback?, errorCallback?, finallyCallback?){
        this.httpService.delete(`${this.prefixApi}/typeInformation/${typeInformationId}`, {}, {}, callback, errorCallback, finallyCallback);
    }

    createTypeInformation(data, callback?, errorCallback?, finallyCallback?){
        this.httpService.post(`${this.prefixApi}/typeInformation`, {}, data, {}, callback, errorCallback, finallyCallback);
    }

    updatedTypeInformation(typeInformationId, data, callback?, errorCallback?, finallyCallback?){
        this.httpService.put(`${this.prefixApi}/typeInformation/${typeInformationId}`, {}, data, {}, callback, errorCallback, finallyCallback);
    }

    detailTypeInformation(typeInformationId, callback?, errorCallback?, finallyCallback?){
        this.httpService.get(`${this.prefixApi}/typeInformation/detail/${typeInformationId}`, {}, {}, callback, errorCallback, finallyCallback);
    }

    searchTypeInformation(params, callback?, errorCallback?, finallyCallback?){
        this.httpService.get(`${this.prefixApi}/typeInformation`, {}, params, callback, errorCallback, finallyCallback);
    }

    getAllTypeInformation(callback?, errorCallback?, finallyCallback?){
        this.httpService.get(`${this.prefixApi}/typeInformation/getAll`, {}, {}, callback, errorCallback, finallyCallback);
    }

    checkExistTypeInformation(name, callback?, errorCallback?, finallyCallback?){
        this.httpService.get(`${this.prefixApi}/typeInformation/checkExisted`, {}, {name}, callback, errorCallback, finallyCallback);
    }

    getTypeInformationByTypeConnection(typeConnectionId, callback?, errorCallback?, finallyCallback?){
        this.httpService.get(`${this.prefixApi}/typeInformation/${typeConnectionId}`, {}, {}, callback, errorCallback, finallyCallback);
    }

    changeStatusTypeInformation(typeInformationId, callback?, errorCallback?, finallyCallback?){
        this.httpService.put(`${this.prefixApi}/typeInformation/changeStatus/${typeInformationId}`, {}, {}, {}, callback, errorCallback, finallyCallback);
    }

    upload(file, callback?, errorCallback?, finallyCallback?){
        this.httpService.upload(`/media/upload`, file, {}, {}, callback, errorCallback, finallyCallback)
    }

    getResource(fileId, callback){
        this.httpService.download(`/media/download/${fileId}`, {}, {}, callback);
    }

    searchConnection(params, callback?, errorCallback?, finallyCallback?){
        this.httpService.get(`${this.prefixApi}`, {}, params, callback, errorCallback, finallyCallback);
    }

    searchDataRaw(params, callback?, errorCallback?, finallyCallback?){
        this.httpService.get(`${this.prefixApi}/connection/dataRaw`, {}, params, callback, errorCallback, finallyCallback);
    }

    checkConnectionInformation(typeInformationId, data, callback?, errorCallback?, finallyCallback?){
        this.httpService.post(`${this.prefixApi}/checkConnection/${typeInformationId}`, {}, data, {}, callback, errorCallback, finallyCallback);
    }

    createConnectionInformation(data, callback?, errorCallback?, finallyCallback?){
        this.httpService.post(`${this.prefixApi}`, {}, data, {}, callback, errorCallback, finallyCallback);
    }

    updateConnectionInformation(connectId, data, callback?, errorCallback?, finallyCallback?){
        this.httpService.put(`${this.prefixApi}/connection/${connectId}`, {}, data, {}, callback, errorCallback, finallyCallback);
    }

    detailConnectionInformation(connectId, callback?, errorCallback?, finallyCallback?){
        this.httpService.get(`${this.prefixApi}/connection/${connectId}`, {}, {}, callback, errorCallback, finallyCallback);
    }

    deleteConnectionInformation(connectId, callback?, errorCallback?, finallyCallback?){
        this.httpService.delete(`${this.prefixApi}/connection/${connectId}`, {}, {}, callback, errorCallback, finallyCallback);
    }

    synchroniseData(connectId, callback?, errorCallback?, finallyCallback?){
        this.httpService.put(`${this.prefixApi}/synchroniseData/${connectId}`, {}, {}, {}, callback, errorCallback, finallyCallback);
    }

    firstSynchroniseData(connectId, callback?, errorCallback?, finallyCallback?){
        this.httpService.put(`${this.prefixApi}/firstSynchroniseData/${connectId}`, {}, {}, {}, callback, errorCallback, finallyCallback);
    }

    viewFileForConnection(connectId, callback?, errorCallback?, finallyCallback?){
        this.httpService.get(`${this.prefixApi}/connection/viewFile/${connectId}`, {}, {}, callback, errorCallback, finallyCallback);
    }

    searchFile(params,callback?, errorCallback?, finallyCallback?){
        this.httpService.get(`${this.prefixApi}/connection/searchFile`, {}, params, callback, errorCallback, finallyCallback);
    }

    deleteFiles(fileIds, callback?, errorCallback?, finallyCallback?){
        this.httpService.post(`${this.prefixApi}/connection/deleteFiles`, {}, fileIds, {}, callback, errorCallback, finallyCallback);
    }

    uploadLoadSharpoint(files, connectionId, callback?, errorCallback?, finallyCallback?){
        this.httpService.uploads(`/media/upload/sharepoint/${connectionId}`, files, {}, {}, callback, errorCallback, finallyCallback);
    }

    checkAccessTokenInformation(typeInformationId, data, callback?, errorCallback?, finallyCallback?){
        this.httpService.post(`${this.prefixApi}/checkAccessToken/${typeInformationId}`, {}, data, {}, callback, errorCallback, finallyCallback);
    }

    waitNotify(typeInformationId, transactionId, callback?, errorCallback?, finallyCallback?){
        this.httpService.get(`${this.prefixApi}/connection/waitNotify/${typeInformationId}/${transactionId}`, {}, {}, callback, errorCallback, finallyCallback);
    }

    checkMainAccount(params, callback?, errorCallback?, finallyCallback?){
        this.httpService.get(`${this.prefixApi}/connection/checkExistMainAccount`, {}, params, callback, errorCallback, finallyCallback);
    }

    getLinkStorageDataUser(callback?, errorCallback?, finallyCallback?){
        this.httpService.get(`${this.prefixApi}/getLinkStorageDataUser`, {}, {}, callback, errorCallback, finallyCallback);
    }

    checkConditionTypeInformation(typeInformationId, callback?, errorCallback?, finallyCallback?){
        this.httpService.get(`${this.prefixApi}/checkConditionTypeInformation/${typeInformationId}`, {}, {}, callback, errorCallback, finallyCallback);
    }
}