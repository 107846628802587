import { Component, OnDestroy, OnInit, Inject, ChangeDetectorRef  } from "@angular/core"
import { ObservableService } from "src/app/service/comon/observable.service";
import { CONSTANTS } from "src/app/service/comon/constants";
import {  Subscription, timeout } from "rxjs";
import { ConfirmationService, MessageService, ConfirmEventType } from 'primeng/api';
import { TranslateService } from "src/app/service/comon/translate.service";
@Component({
    selector:"message-common",
    templateUrl: "./message-common.component.html",
    providers: [ConfirmationService, MessageService]
})
export class MessageCommonComponent implements OnInit, OnDestroy {
    subscriptionMessage: Subscription;
    blockedDocument: boolean = false;
    constructor(@Inject(ObservableService) private observableService: ObservableService,
                    private confirmationService: ConfirmationService, private messageService: MessageService,
                    private cd: ChangeDetectorRef,
                    private tranService: TranslateService) {
        observableService.init(CONSTANTS.OBSERVABLE.KEY_MESSAGE_COMMON);
        this.subscriptionMessage = observableService.subscribe(CONSTANTS.OBSERVABLE.KEY_MESSAGE_COMMON, {
            next: this.handleNext.bind(this),
            error: this.handleError.bind(this),
            complete: this.handleComplete.bind(this)
        })
    }
    ngOnInit(): void {
        
    }

    showConfirm(data){
        this.confirmationService.confirm({
            message: data.message,
            header: data.title,
            icon: 'pi pi-exclamation-triangle',
            acceptLabel: this.tranService.translate("global.button.yes"),
            rejectLabel: this.tranService.translate("global.button.no"),
            acceptButtonStyleClass: "p-button-info",
            rejectButtonStyleClass: "p-button-secondary",
            accept: () => {
                if(data.action.ok){
                    data.action.ok();
                }
            },
            reject: (type) => {
                if(data.action.cancel){
                    data.action.cancel();
                }
            }
        });
    }

    showNotify(data){
        this.confirmationService.confirm({
            message: data.message,
            header: data.title,
            icon: 'pi ' + (data.icon ? data.icon : 'pi-exclamation-triangle'),
            acceptLabel: data.acceptLabel ? data.acceptLabel : this.tranService.translate("global.button.yes"),
            rejectLabel: data.rejectLabel ? data.rejectLabel : this.tranService.translate("global.button.no"),
            acceptButtonStyleClass: "p-button-info",
            rejectButtonStyleClass: "p-button-secondary",
            accept: () => {
                if(data.action.ok){
                    data.action.ok();
                }
            },
            reject: (type) => {
                if(data.action.cancel){
                    data.action.cancel();
                }
            }
        });
    }

    showInfo(data){
        let id = new Date().getTime() + "";
        if(data.timeout){
            this.messageService.add({ severity: 'info', summary: data.summary || 'Info', detail: data.message, life: data.timeout, id});
        }else{
            this.messageService.add({ severity: 'info', summary: data.summary || 'Info', detail: data.message, id});
        }
        this.replaceLink(id, data.data, data.message);
    }

    showError(data){
        let id = new Date().getTime() + "";
        if(data.timeout){
            this.messageService.add({ severity: 'error', summary: data.summary || 'Error', detail: data.message, life: data.timeout, id });
        }else{
            this.messageService.add({ severity: 'error', summary: data.summary || 'Error', detail: data.message, id});
        }
        this.replaceLink(id, data.data, data.message);
    }

    showWarning(data){
        if(data.timeout){
            this.messageService.add({ severity: 'warn', summary: data.summary || 'Warning', detail: data.message,  life: data.timeout});
        }else{
            this.messageService.add({ severity: 'warn', summary: data.summary || 'Warning', detail: data.message });
        }
    }

    showSuccess(data){
        if(data.timeout){
            this.messageService.add({ severity: 'success', summary: data.summary || 'Success', detail: data.message, life: data.timeout });
        }else{
            this.messageService.add({ severity: 'success', summary: data.summary || 'Success', detail: data.message });
        }
    }

    showLoad(data){
        this.blockedDocument = true;
    }

    hiddenLoad(data){
        this.blockedDocument = false;
        this.cd.markForCheck();
    }

    handleNext(data:{message?:string, action?:{ok?:Function, cancel?:Function}, type: "confirm"|"info"|"error"|"warning"|"load"|"success"|"notify", isShow?: boolean, icon?: string, acceptLabel?: string, rejectLabel?: string}){
        if(data.type == "confirm"){
            this.showConfirm(data);
        }else if(data.type == "info"){
            this.showInfo(data);
        }else if(data.type == "error"){
            this.showError(data);
        }else if(data.type == "warning"){
            this.showWarning(data);
        }else if(data.type == "load"){
            if(data.isShow){
                this.showLoad(data);
            }else{
                this.hiddenLoad(data);
            }
        }else if(data.type == "success"){
            this.showSuccess(data);
        }else if(data.type == "notify"){
            this.showNotify(data);
        }
    }

    handleError(error){
        console.log("handle error: ",error);
    }

    handleComplete(){
        console.log("complete");
    }

    replaceLink(id, data, message){
        setTimeout(function(){
            let classContent= "p-toast-detail";
            if(id && data && data.links && message && message.indexOf("@@links") >= 0){
                if(document.getElementById(id) && document.getElementById(id).getElementsByClassName(classContent)){
                    for(let index = 0; index < data.links.length; index++){
                        let links = data.links[index].map(el => `<a href="${el.link[0]}">${el.display}</a>`)
                        let textLinkReplace = links.join(",");
                        message = message.replace(`@@links${index}`, textLinkReplace);
                    }
                    document.getElementById(id).getElementsByClassName(classContent)[0].innerHTML = message;
                }
            }
        },100);
    }

    ngOnDestroy(): void {
        this.subscriptionMessage.unsubscribe();
    }
}