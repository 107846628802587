import global from "./global";

import account from "./account";
import error from "./error";
import login from "./login";
import template from "./template";
import connect from "./connect";
import roles from "./roles";
import permission from "./permission";
import log from "./log";

export const vi = {
    global,
    account,
    error,
    login,
    template,
    connect,
    roles,
    permission,
    log
}
