import { Component, ElementRef, Injector, OnInit, ViewChild, ViewChildren } from "@angular/core";
import { FormBuilder } from "@angular/forms";
import { MenuItem } from "primeng/api";
import { ComponentBase } from "src/app/component.base";
import { CONSTANTS } from "src/app/service/comon/constants";
import { ConnectionService } from "src/app/service/connect/connect.service";
import { OptionInputFile } from "src/app/template/common-module/input-file/input.file.component";
import { ColumnInfo, OptionTable } from "src/app/template/common-module/table/table.component";
import { environment } from "src/environments/environment";

interface UploadEvent {
    originalEvent: Event;
    files: File[];
}

@Component({
    selector: 'app-connection-data-raw-component',
    templateUrl: './app.connection.data.raw.component.html'
})
export class AppConnectionDataRawComponent extends ComponentBase implements OnInit{
    searchInfo: {
        typeInformation: number | null,
        typeConnection: number | null,
        userId: number | null,
    } = {
        typeInformation: null,
        typeConnection: null,
        userId: null,
    }
    items: MenuItem[];
    home: MenuItem;
    selectItems: Array<any> = [];
    columns: Array<ColumnInfo>;
    optionTable: OptionTable;
    dataSet: {
        content: Array<any>,
        total: number
    } = {
        content: [],
        total: 0
    };
    pageNumber: number = 0;
    pageSize: number = 10;
    sort: string = "Ngày đặt hàng,desc";
    formSearch: any;
    listTypeConnection: Array<any> = [];
    listTypeInformation: Array<any> = [];
    listAllTypeInformation: Array<any> = [];
    isAdmin = this.sessionService.userInfo.type == CONSTANTS.USER_TYPE.ADMIN;
    constructor(
                private connectionService:  ConnectionService,
                private formBuilder: FormBuilder,
                private eRef: ElementRef,
                private injector: Injector)
    {
        super(injector);
    }

    ngOnInit(): void {
        let me = this;
        this.items = [{ label: this.tranService.translate("global.menu.managerData") }, { label: this.tranService.translate("global.menu.listDataRaw") }];
        this.home = { icon: 'pi pi-home', routerLink: '/' };
        this.formSearch = this.formBuilder.group(this.searchInfo);
        this.getListTypeConnection();
        this.getListTypeInformation();

        this.optionTable = {
            hasClearSelected: false,
            hasShowChoose: false,
            hasShowIndex: true,
            hasShowToggleColumn: true,
        }
        this.columns = [
            {
                name: "Ngày đặt hàng",
                key: "Ngày đặt hàng",
                size: "250px",
                align: "left",
                isShow: true,
                isSort: false,
                funcConvertText(value){
                    return me.utilService.convertLongDateTImeToString(value);
                }
            },
            {
                name: "Trạng Thái Đơn Hàng",
                key: "Trạng Thái Đơn Hàng",
                size: "250px",
                align: "left",
                isShow: true,
                isSort: false,
            },
            {
                name: "Lý do huỷ",
                key: "Lý do huỷ",
                size: "250px",
                align: "left",
                isShow: false,
                isSort: false,
            },
            {
                name: "ID sản phẩm",
                key: "ID sản phẩm",
                size: "250px",
                align: "left",
                isShow: false,
                isSort: false,
            },
            {
                name: "SKU sản phẩm",
                key: "SKU sản phẩm",
                size: "250px",
                align: "left",
                isShow: true,
                isSort: false,
            },
            {
                name: "SKU phân loại hàng",
                key: "SKU phân loại hàng",
                size: "250px",
                align: "left",
                isShow: true,
                isSort: false,
            },
            {
                name: "Giá gốc",
                key: "Giá gốc",
                size: "250px",
                align: "left",
                isShow: true,
                isSort: false,
            },
            {
                name: "Số lượng",
                key: "Số lượng",
                size: "250px",
                align: "left",
                isShow: true,
                isSort: false,
            },
            {
                name: "Giá bán trước chiết khấu",
                key: "Giá bán trước chiết khấu",
                size: "250px",
                align: "left",
                isShow: false,
                isSort: false,
            },
            {
                name: "Người bán trợ giá",
                key: "Người bán trợ giá",
                size: "250px",
                align: "left",
                isShow: false,
                isSort: false,
            },
            {
                name: "Tổng giá bán",
                key: "Tổng giá bán",
                size: "250px",
                align: "left",
                isShow: false,
                isSort: false,
            },
            {
                name: "Mã giảm giá của Shop - voucher",
                key: "Mã giảm giá của Shop - voucher",
                size: "250px",
                align: "left",
                isShow: false,
                isSort: false,
            },
            {
                name: "Tổng số tiền người mua thanh toán",
                key: "Tổng số tiền người mua thanh toán",
                size: "250px",
                align: "left",
                isShow: false,
                isSort: false,
            },
            {
                name: "Thời gian hoàn thành đơn hàng",
                key: "Thời gian hoàn thành đơn hàng",
                size: "250px",
                align: "left",
                isShow: false,
                isSort: false,
                funcConvertText(value){
                    return me.utilService.convertLongDateTImeToString(value);
                }
            },
            {
                name: "Phí cố định",
                key: "Phí cố định",
                size: "250px",
                align: "left",
                isShow: false,
                isSort: false,
            },
            {
                name: "Phí Dịch Vụ",
                key: "Phí Dịch Vụ",
                size: "250px",
                align: "left",
                isShow: false,
                isSort: false,
            },
            {
                name: "Phí thanh toán",
                key: "Phí thanh toán",
                size: "250px",
                align: "left",
                isShow: false,
                isSort: false,
            },
            {
                name: "Kênh",
                key: "Kênh",
                size: "250px",
                align: "left",
                isShow: false,
                isSort: false,
            },
        ]
        this.search(this.pageNumber, this.pageSize, this.sort, this. searchInfo);
    }

    getListTypeConnection(){
        let me = this;
        this.connectionService.getListTypeConnection((response)=> {
            me.listTypeConnection = response;
        })
    }

    getListTypeInformation(){
        let me = this;
        if(this.searchInfo.typeConnection){
            this.connectionService.getTypeInformationByTypeConnection(this.searchInfo.typeConnection, (response)=> {
                me.listTypeInformation = response;
            })
        }else{
            this.connectionService.searchTypeInformation({page: 0, size: 99999999}, (response)=>{
                me.listTypeInformation = response.content || [];
                me.listAllTypeInformation = response.content || [];
            })
        }
    }

    search(page, size, sort, params){
        if(((params.userId || "") == "" && this.isAdmin) || (params.typeInformation || "") == "") return;
        let me = this;
        let dataParams = {
            page,
            size,
            sort,
            ...params
        }
        Object.keys(dataParams).forEach(key => {
            if(dataParams[key] === undefined || dataParams[key] === null || dataParams[key] ===''){
                delete dataParams[key];
            }
        })
        me.messageCommonService.onload();
        this.connectionService.searchDataRaw(dataParams, (response)=>{
            me.dataSet = {
                content: response.content,
                total: response.totalElements
            }
        }, null, ()=> {
            me.messageCommonService.offload();
        })
    }

    onSubmitSearch(){
        this.search(this.pageNumber, this.pageSize, this.sort, this.searchInfo);
    }
}