import { Component, Input, OnInit, Output, DoCheck, AfterViewChecked, Inject, Injector} from "@angular/core";
import { EventEmitter } from "@angular/core";
import { options } from "@fullcalendar/core/preact";
import { TableSelectAllChangeEvent } from "primeng/table";
import { ComponentBase } from "src/app/component.base";
import { TranslateService } from "src/app/service/comon/translate.service";
export interface ColumnInfo{
    name: string,//tên hiển thị
    key: string,//key lấy giá trị hiển thị
    size: string,//kích thước cột
    align: "left"|"right"|"center",//căn lề
    isShow: boolean,//có cho hiển thi hay không
    isSort: boolean,// có cho sắp xếp hay không
    style?: {[className: string]: any},// css riêng
    className?: string | Array<string>,// class css riêng
    funcGetClassname?: (value: any) => string | Array<string>,//hàm chuyển đổi class css
    funcConvertText?: (value: any, item?: any) => string,// hàm chuyển đổi giá trị hiển thị
    funcGetRouting?:(item: any) => Array<any>,// hàm lấy giá trị link
    funcClick?: (id:any, item:any) => void//hàm xử lý khi click,
    funcTooltip?: (value: any, item: any) => string // hàm lấy giá trị cho tooltip
    funcCreateId?: (item: any) => string
}
export interface ActionInfo{
    icon: string,//icon là gì
    tooltip: string, // text chú thích là gì
    func: (id: any, item:any, ...args: any)=>void,//hàm xủ lý là gì 
    funcAppear?: (id:any, item: any)=>boolean// hàm xử lý có cho phép hiển thị hay không
}
export interface OptionTable{
    hasClearSelected: boolean,//có clear lựa chọn khi chuyển trang hay không
    hasShowChoose?: boolean,//có hiển thị ô lựa chọn hay không
    hasShowIndex?: boolean,//có hiển thị cột index hay không
    hasShowToggleColumn?: boolean,// có hiển thị nút lựa chọn cột hiển thị hay không
    hasShowJumpPage?: boolean,// có hiển thị ô nhảy trang hay không
    action?: Array<ActionInfo>;// danh sách các nút ở cột thao tác
    paginator?: boolean;// có phân trang hay không
}
@Component({
    selector: "table-qlda",
    templateUrl: './table.component.html'
})
export class TableCustomComponent extends ComponentBase implements OnInit, DoCheck, AfterViewChecked{
    constructor(@Inject(TranslateService) public transService: TranslateService, private injector: Injector) {
        super(injector);
    }
    @Input() tableId?: string;
    @Input() selectItems?: Array<any> = [];// giá trị được lựa chọn
    @Input() labelTable?: string;// text chú thích bảng
    @Output() selectItemsChange: EventEmitter<Array<any>> = new EventEmitter();

    @Input() columns!: Array<ColumnInfo>;//danh sách cột
    @Input() dataSet!: {
        content: Array<Object>,
        total: number
    };

    @Input() loadData?: (page, limit, sort, params)=>void;//hàm load dữ liệu

    @Input() options!:OptionTable;//option của table
    @Input() pageNumber?: number = 0;// trang hiển thị
    @Input() pageSize?: number=0;// số lượng dòng trên 1 trang
    @Input() sort?: string=""; // format sắp xếp
    @Input() params?: any={};// tham số tìm kiếm
    @Input() fieldId!: string;//key được lựa chọn làm id
    @Input() rowsPerPageOptions?: Array<number> = [5, 10, 20, 25, 50];// danh sách lựa chọn số lượng dòng trên 1 trang
    @Input() scrollHeight?: string = 'flex'//độ cao tối đa của table

    clearSelected = this.tranService.translate('global.text.clearSelected');
    columnShows: Array<string>;
    rowFirst: number;
    maxPage: number;
    pageCurrent: number;
    selectItemsOld: Array<any>;
    oldSort: any;
    pageNumberOld: number;
    modelSelected: Array<any>;
    dataSetOld: any = {};

    ngOnInit(): void {
        if(!this.options){
            this.options = {
                hasClearSelected: true,
                hasShowChoose: false,
                hasShowIndex: false,
                hasShowToggleColumn: false,
                hasShowJumpPage: false,
                action: [],
                paginator: false
            }
        }
        if(this.options.paginator !== false){
            this.options.paginator = true;
            this.rowFirst = this.pageNumber*this.pageSize;
            this.pageNumberOld = this.pageNumber;
            this.pageCurrent = this.pageNumber + 1;
        }
        let flagCheckSession = false;
        if(this.options.hasShowToggleColumn){
            let dataSessionTable = localStorage.getItem('dataSessionTable');
            if(dataSessionTable){
                dataSessionTable = JSON.parse(dataSessionTable);
                if(dataSessionTable[this.sessionService.userInfo.username]){
                    if(dataSessionTable[this.sessionService.userInfo.username][this.tableId]){
                        this.columnShows = [...dataSessionTable[this.sessionService.userInfo.username][this.tableId]];
                        flagCheckSession = true;
                    }
                }
            }
        }
        if(!flagCheckSession){
            this.columnShows = this.columns.filter(column => column.isShow)
                                        .map(column => column.key);
        }
        this.selectItemsOld = [...this.selectItems];
    }

    ngDoCheck(){
        let me = this;
        if(JSON.stringify(this.selectItems) !== JSON.stringify(this.selectItemsOld)){
            setTimeout(() => {
                this.selectItemsChange.emit(this.selectItems);
                this.selectItemsOld = [...this.selectItems];
            })
        }
        if(JSON.stringify(this.dataSet) !== JSON.stringify(this.dataSetOld)){
            this.dataSetOld = {...this.dataSet};
            if(!this.options.hasClearSelected){
                let idSelecteds = this.selectItems.map(el => el[me.fieldId]);
                this.modelSelected = this.dataSet.content.filter(el => idSelecteds.includes(el[me.fieldId]));
            }
        }
        if(this.pageNumber != this.pageNumberOld && this.options.paginator){
            this.rowFirst = this.pageNumber*this.pageSize;
            this.pageCurrent = this.pageNumber + 1;
            this.pageNumberOld = this.pageNumber;
        }
        if(this.selectItems.length == 0){
            this.modelSelected = [];
        }else{
            let valueSelected = this.selectItems.map(el => el[me.fieldId]);
            this.modelSelected = this.dataSet.content.filter(el => valueSelected.includes(el[me.fieldId]))
        }
    }

    ngAfterViewChecked(){
    }

    pageChange(event){//{first/rows}
        this.pageSize = event.rows;
        this.pageNumber = event.first/event.rows;
        this.pageCurrent = this.pageNumber + 1;
        this.modelSelected = [];
        if(this.options.hasClearSelected === true){
            this.selectItems = [];
        }
        this.loadData(this.pageNumber, this.pageSize, this.sort, this.params);
    }

    filterColumnShow(columns){
        return columns.filter(el => this.columnShows.includes(el.key));
    }

    getMaxPage(){
        if(this.dataSet.total % this.pageSize == 0){
            return this.dataSet.total/this.pageSize;
        }else{
            return Math.ceil(this.dataSet.total/this.pageSize);
        }
    }

    jumpPage(){
        this.modelSelected = [];
        if(this.options.hasClearSelected === true){
            this.selectItems = [];
        }
        this.pageNumber = this.pageCurrent - 1;
        this.rowFirst = this.pageNumber * this.pageSize;
        this.loadData(this.pageNumber, this.pageSize, this.sort, this.params);
    }

    handleSort(event){
        if(!this.checkSort(event.field)){
            return;
        }
        if(JSON.stringify(this.oldSort) == JSON.stringify(event)) return;
        this.oldSort = event;
        this.modelSelected = [];
        if(this.options.hasClearSelected === true){
            this.selectItems = [];
        }
        this.sort = `${event.field},${event.order == 1 ? 'asc': 'desc'}`;
        this.loadData(this.pageNumber, this.pageSize, this.sort, this.params);
    }

    checkSort(field): boolean{
        for(let i = 0;i<this.columns.length;i++){
            let column = this.columns[i];
            if(column.key == field){
                return column.isSort;
            }
        }
        return false;
    }

    getSortField(){
        if(this.sort){
            return this.sort.split(",")[0];
        }
        return "";
    }

    getSortOrder(){
        if(this.sort){
            let typeSort = this.sort.split(",")[1];
            return typeSort == 'asc' ? 1 : -1;
        }
        return "";
    }

    checkEmpty(){
        return (this.dataSet?.content || []).length == 0;
    }

    getNumberColumnShow(){
        return this.filterColumnShow(this.columns).length 
                + (this.options.action?1:0) + (this.options.hasShowToggleColumn == true?1:0)
                + (this.options.hasShowIndex?1:0) + (this.options.hasShowChoose?1:0);
    }

    filterAction(actions: Array<ActionInfo>, item: any, id: string){
        return actions.filter(el => {
            return el.funcAppear == undefined || el.funcAppear(id, item);
        })
    }

    handleSelectAllChange(values){
        let me = this;
        if(!this.options.hasClearSelected){
            let idInPage = this.dataSet.content.map(el => el[me.fieldId]);
            let ortherItem = this.selectItems.filter(el => !idInPage.includes(el[me.fieldId]));
            this.selectItems = [...ortherItem, ...values];
        }else{
            this.selectItems = [...values]
        }
    }

    handleClickText(item: ColumnInfo, data){
        if(item.funcClick){
            item.funcClick(data[this.fieldId], data);
        }
    }

    handleContextMenu(event){
        let me = this;
        me.utilService.copyToClipboard(event.target.innerHTML, () => {
            me.messageCommonService.success(me.tranService.translate("global.message.copied"));
        });
        event.preventDefault();
    }

    columnShowChanged(value){
        if(this.options.hasShowToggleColumn){
            if(this.sessionService.userInfo){
                if((this.tableId || "").trim().length > 0){
                    let dataSessionTable: any = localStorage.getItem("dataSessionTable");
                    if(dataSessionTable){
                        dataSessionTable = JSON.parse(dataSessionTable);
                        dataSessionTable[this.sessionService.userInfo.username] = {[this.tableId]: value};
                    }else{
                        dataSessionTable = {[this.sessionService.userInfo.username]: {[this.tableId]: value}};
                    }
                    localStorage.setItem("dataSessionTable", JSON.stringify(dataSessionTable));
                }
            }
        }
    }
}