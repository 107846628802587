export default {
    label: {
        rolename: "Tên nhóm quyền",
        usertype: "Loại nhóm quyền",
        status: "Trạng thái",
        rolelist: "Danh sách quyền",
        errorPattern: "Sai định dạng. Chỉ cho phép (a-z, A-Z, 0-9, . - , dấu cách, tiếng Việt)",
    },
    text: {
        inputRoleName: "Tên nhóm quyền",
        selectUserType: "Chọn loại nhóm quyền",
        status: "Chọn trạng thái",
    },
    status: {
        all: "Tất cả",
        active: "Hoạt động",
        inactive: "Không hoạt động",
    },
    type: {
        all: "All",
        admin: "Admin",
        customer: "Khách hàng",
        agency: "Đại lý",
        trial: "Dùng thử"
    }
}
