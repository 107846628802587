export default {
    ACCOUNT: {
        CREATE: "createAccount",
        UPDATE: "updateAccount",
        DELETE: "deleteAccount",
        SEARCH: "searchAccount",
        DETAIL: "detailAccount",
        EMBED: "embedAccount",
        CHANGE_STATUS: "changeStatusAccount",
    },
    DASHBOARD: {
        CREATE: "createDashboard",
        UPDATE: "updateDashboard",
        DELETE: "deleteDashboard",
        SEARCH: "searchDashboard",
        VIEW: "viewDashboard",
        MANAGER: "managerDashboard",
        DETAIL: "detailDashboard",
        APPLY: "applyDashboard",
        CANCEL: "cancelDashboard",
        CHANGE_STATUS: "changeStatusDashboard",
    },

    TYPE_INFORMATION: {
        CREATE: "createTypeInformation",
        UPDATE: "updateTypeInformation",
        DELETE: "deleteTypeInformation",
        SEARCH: "searchTypeInformation",
        DETAIL: "detailTypeInformation",
    },

    TYPE_CONNECTION: {
        SEARCH: "searchTypeConnection",
    },

    CONNECTION: {
        CREATE: "createConnection",
        UPDATE: "updateConnection",
        DELETE: "deleteConnection",
        SEARCH: "searchConnection",
        DETAIL: "detailConnection",
        SYNC: "syncConnection",
        UPLOAD_FILE: "uploadFileConnection",
    },

    FILE: {
        DELETE: "deleteFile",
        SEARCH: "searchFile",
    },

    DATA_COLLECT: {
        SEARCH: "searchDataCollect",
    },

    PERMISSIONS: {
        SEARCH: "searchPermission",
    },

    ROLE: {
        CREATE: "createRole",
        UPDATE: "updateRole",
        DELETE: "deleteRole",
        SEARCH: "searchRole",
        DETAIL: "detailRole",
        CHANGE_STATUS: "changeStatusRole",
    },

    LOG: {
        VIEW: "viewLog"
    }
}