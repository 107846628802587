export default{
    
    status: {
        400: "Yêu cầu không hợp lệ",
        401: "Chưa xác thực",
        403: "Không có quyền",
        404: "Không tìm thấy đối tượng",
        406: "Yêu cầu không được chấp nhận",
        408: "Yêu cầu không phản hồi",
        409: "Xung đột dữ liệu",
        500: "Lỗi hệ thống"
    }
}
