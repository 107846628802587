import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { TableModule } from 'primeng/table';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { ButtonModule } from 'primeng/button';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { CheckboxModule } from 'primeng/checkbox';
import { InputNumberModule } from 'primeng/inputnumber';
import { TableCustomComponent } from "./table/table.component";
import { ChooseLanguageComponent } from "./choose-language/choose-language.component";
import {MessageCommonComponent} from "./message-common/message-common.component"
import { ConfirmDialogModule } from 'primeng/confirmdialog'
import { ToastModule } from 'primeng/toast';
import { BlockUIModule } from 'primeng/blockui';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { TooltipModule } from 'primeng/tooltip';
import { InputFileCustomComponent } from "./input-file/input.file.component";
import { CustomCombobox } from "./combobox-lazyload/combobox.lazyload";
import { InputTextModule } from "primeng/inputtext";
import { DropdownModule } from "primeng/dropdown";
import { SanitizePipe } from "src/app/pipe/sanitize.pipe";
import { TableInputComponent } from "./table/table.input.component";
import { CalendarModule } from 'primeng/calendar';
import { DialogModule } from "primeng/dialog";
import { CaptchaComponent } from "./captcha/captcha";
import { ChartModule } from "primeng/chart";
import { AccountService } from "src/app/service/account/AccountService";
import { RolesService } from "src/app/service/account/RolesService";
import { ConnectionService } from "src/app/service/connect/connect.service";
@NgModule({
    imports: [
        CommonModule,
        TableModule,
        OverlayPanelModule,
        ButtonModule,
        FormsModule,
        ReactiveFormsModule,
        CheckboxModule,
        InputNumberModule,
        InputTextModule,
        DropdownModule,
        ConfirmDialogModule,
        ToastModule,
        BlockUIModule,
        ProgressSpinnerModule,
        RouterModule,
        TooltipModule,
        CalendarModule,
        DialogModule,
        ChartModule
    ],
    declarations:[
        TableCustomComponent,
        ChooseLanguageComponent,
        MessageCommonComponent,
        InputFileCustomComponent,
        CustomCombobox,
        SanitizePipe,
        TableInputComponent,
        CaptchaComponent,
    ],
    exports:[
        TableCustomComponent,
        ChooseLanguageComponent,
        MessageCommonComponent,
        InputFileCustomComponent,
        CustomCombobox,
        SanitizePipe,
        TableInputComponent,
        CaptchaComponent,
    ],
    providers:[
        AccountService, RolesService, ConnectionService
    ]
})
export class CommonQldaModule{}