<div class="layout-topbar">
    <a class="layout-topbar-logo" routerLink="">
        <!-- <img src="assets/layout/images/{{layoutService.config().colorScheme === 'light' ? 'logo-dark' : 'logo-white'}}.svg" alt="logo"> -->
        <img src="assets/taki_ecom.png" alt="logo">
        <!-- <span>Dashboard Managment</span> -->
    </a>

    <button #menubutton class="p-link layout-menu-button layout-topbar-button" (click)="layoutService.onMenuToggle()">
        <i class="pi pi-bars"></i>
    </button>

    <button #topbarmenubutton class="p-link layout-topbar-menu-button layout-topbar-button" (click)="layoutService.showProfileSidebar()">
        <i class="pi pi-ellipsis-v"></i>
    </button>

    <div #topbarmenu class="layout-topbar-menu flex flex-row justify-content-start align-items-center" [ngClass]="{'layout-topbar-menu-mobile-active': layoutService.state.profileSidebarVisible}">
        <div class="ml-2">
            <div (click)="session.toggle($event)" class="cursor-poiter"><i class="pi pi-fw pi-user"></i>&nbsp;<span>{{username}}</span></div>
            <p-overlayPanel #session>
                <a class="text-black-alpha-90" [routerLink]="['/profile']" routerLinkActive="router-link-active" >
                    <div class="p-2 hover:surface-300">
                        <i class="pi pi-user-edit"></i>&nbsp;
                        {{tranService.translate("login.label.editProfile")}}
                    </div>
                </a>
                <a class="text-black-alpha-90" (click)="getLinkDatasource()">
                    <div class="p-2 hover:surface-300">
                        <i class="pi pi-folder"></i>&nbsp;
                        {{tranService.translate("connect.label.datasource")}}
                    </div>
                </a>
                <a class="text-black-alpha-90" [routerLink]="['/change-password']" routerLinkActive="router-link-active" >
                    <div class="p-2 hover:surface-300">
                        <i class="pi pi-lock"></i>&nbsp;
                        {{tranService.translate("login.label.changePassword")}}
                    </div>
                </a>
                <a class="text-black-alpha-90 cursor-pointer" (click)="logout()" routerLinkActive="router-link-active" >
                    <div class="p-2 hover:surface-300">
                        <i class="pi pi-sign-out"></i>&nbsp;
                        {{tranService.translate("login.label.logout")}}
                    </div>
                </a>
            </p-overlayPanel>
        </div>
    </div>
    
</div>
