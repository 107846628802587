import { HttpClient, HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Inject, Injectable,InjectionToken } from "@angular/core";
import { MessageCommonService } from "./message-common.service";

import { Observable, TimeoutError } from 'rxjs';
import { timeout } from 'rxjs/operators';
import { TranslateService } from "./translate.service";
import { DomSanitizer } from "@angular/platform-browser";
import {SessionService} from "../session/SessionService";
import { CONSTANTS } from "./constants";
import { environment } from "src/environments/environment";

export const DEFAULT_TIMEOUT = new InjectionToken<number>('defaultTimeout');

@Injectable({ providedIn: 'root'})
export class TimeoutInterceptor implements HttpInterceptor {
  constructor(@Inject(DEFAULT_TIMEOUT) protected defaultTimeout: number) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const timeoutValue = req.headers.get('timeout') || this.defaultTimeout;
    const timeoutValueNumeric = Number(timeoutValue);

    return next.handle(req).pipe(timeout(timeoutValueNumeric));
  }
}

@Injectable({ providedIn: 'root'})
export class HttpService{
    headers: object;
    baseUrl: string;
    baseUrlFake: string;
    constructor(@Inject(HttpClient) private httpClient: HttpClient,
                        private messageCommonService: MessageCommonService,
                        private tranService: TranslateService,
                        private sanitizer: DomSanitizer) {
        this.headers = {
            Authorization: 'Bearer ' + localStorage.getItem("token"),
            timeout: CONSTANTS.MAX_TIME_HTTP_WAIT,
            "ngrok-skip-browser-warning": "69420"
        }
        this.baseUrl = environment.baseUrl;
        // this.baseUrl = "http://10.2.9.144:8080/api"
        this.baseUrlFake = environment.baseUrlFake;
    }

    handleCommonNext(response){
        // this.messageCommonService.offload();
    }

    updateHeader(token) {
        this.headers['Authorization'] = 'Bearer ' + token
    }

    handleCommonError(error){
        console.log(error);
        if(error instanceof TimeoutError){
            this.messageCommonService.error(this.tranService.translate("global.message.timeout"));
        }else if(error instanceof HttpErrorResponse){
            if(error.error){
                let bodyError = error.error.error;
                if(bodyError){
                    let statusError = error.status || 500;
                    let errorCode = bodyError.errorCode || "global.message.error";
                    let field = bodyError.field || "";
                    field = field.substring(1, field.length - 1).split(",");
                    let objectKey = bodyError.object;
                    let timestamp = bodyError.timestamp;
                    let dataMessage = {};
                    for(let i = 0;i < field.length;i++){
                        dataMessage[i] = field[i].trim();
                    }
                    this.messageCommonService.error(this.tranService.translate(errorCode, dataMessage), this.tranService.translate(`error.status.${statusError}`));
                    if(statusError == CONSTANTS.HTTP_STATUS.UNAUTHORIZED){
                        localStorage.clear();
                        window.location.href = "/login";
                    }else if(statusError == CONSTANTS.HTTP_STATUS.FORBIDDEN){
                        debugger
                        window.location.href = "/access";
                    }
                }else{
                    this.messageCommonService.error(this.tranService.translate("global.message.error"), this.tranService.translate("ERROR"));
                }
            }else{
                this.messageCommonService.error(error.status+"");
                if(error.status == CONSTANTS.HTTP_STATUS.UNAUTHORIZED || error.status == CONSTANTS.HTTP_STATUS.FORBIDDEN){
                    localStorage.clear();
                    window.location.href = "/login";
                }
            }
        }else{
            this.messageCommonService.error(this.tranService.translate("global.message.error"))
        }
        // this.messageCommonService.offload();
    }

    handleCommonFinally(){
        // this.messageCommonService.offload();
    }

    public get(url:string, headers:{[key:string]:any}, params:{[key:string]:any}, callback?:Function, errorCallback?:Function, finallyCallback?:Function):void{
        let obs = this.httpClient.get(`${this.baseUrl}${url}`, {
            headers: {
                ...this.headers,
                ...headers,
            },
            params
        });
        let me = this;
        obs.subscribe({
            next: (response)=>{
                if(callback){
                    callback(response);
                }else{
                    me.handleCommonNext(response);
                }
            },
            error: (error)=>{
                if(errorCallback){
                    errorCallback(error);
                }else{
                    me.handleCommonError(error);
                }
                if(finallyCallback){
                    finallyCallback("error");
                }else{
                    me.handleCommonFinally();
                }
            },
            complete: ()=>{
                if(finallyCallback){
                    finallyCallback();
                }else{
                    me.handleCommonFinally();
                }
            }
        })
    }

    public getFake(url:string, headers:{[key:string]:any}, params:{[key:string]:any}, callback?:Function, errorCallback?:Function, finallyCallback?:Function):void{
        let obs = this.httpClient.get(`${this.baseUrlFake}${url}`, {
            headers: {
                ...this.headers,
                ...headers,
            },
            params
        });
        let me = this;
        obs.subscribe({
            next: (response)=>{
                if(callback){
                    callback(response);
                }else{
                    me.handleCommonNext(response);
                }
            },
            error: (error)=>{
                if(errorCallback){
                    errorCallback(error);
                }else{
                    me.handleCommonError(error);
                }
                if(finallyCallback){
                    finallyCallback("error");
                }else{
                    me.handleCommonFinally();
                }
            },
            complete: ()=>{
                if(finallyCallback){
                    finallyCallback();
                }else{
                    me.handleCommonFinally();
                }
            }
        })
    }

    public post(url:string, headers:{[key:string]:any}, data: any,params?:{[key:string]:any}, callback?:Function, errorCallback?:Function, finallyCallback?:Function):void{
        let obs = this.httpClient.post(`${this.baseUrl}${url}`,data, {
            headers: {
                ...this.headers,
                ...headers
            },
            params
        });
        let me = this;
        obs.subscribe({
            next: (response)=>{
                if(callback){
                    callback(response);
                }else{
                    me.handleCommonNext(response);
                }
            },
            error: (error)=>{
                if(errorCallback){
                    console.log("error callback");
                    errorCallback(error);
                }else{
                    me.handleCommonError(error);
                }
                if(finallyCallback){
                    finallyCallback("error");
                }else{
                    me.handleCommonFinally();
                }
            },
            complete: ()=>{
                if(finallyCallback){
                    finallyCallback();
                }else{
                    me.handleCommonFinally();
                }
            }
        })
    }

    public postFake(url:string, headers:{[key:string]:any}, data: any,params?:{[key:string]:any}, callback?:Function, errorCallback?:Function, finallyCallback?:Function):void{
        let obs = this.httpClient.post(`${this.baseUrlFake}${url}`,data, {
            headers: {
                ...this.headers,
                ...headers
            },
            params
        });
        let me = this;
        obs.subscribe({
            next: (response)=>{
                if(callback){
                    callback(response);
                }else{
                    me.handleCommonNext(response);
                }
            },
            error: (error)=>{
                if(errorCallback){
                    errorCallback(error);
                }else{
                    me.handleCommonError(error);
                }
                if(finallyCallback){
                    finallyCallback("error");
                }else{
                    me.handleCommonFinally();
                }
            },
            complete: ()=>{
                if(finallyCallback){
                    finallyCallback();
                }else{
                    me.handleCommonFinally();
                }
            }
        })
    }

    public postNoHeader(url:string, headers:{[key:string]:any}, data: any,params?:{[key:string]:any}, callback?:Function, errorCallback?:Function, finallyCallback?:Function):void{
        let obs = this.httpClient.post(`${this.baseUrl}${url}`,data, {
            headers: {
                ...headers
            },
            params
        });
        let me = this;
        obs.subscribe({
            next: (response)=>{
                if(callback){
                    callback(response);
                }else{
                    me.handleCommonNext(response);
                }
            },
            error: (error)=>{
                if(errorCallback){
                    errorCallback(error);
                }else{
                    me.handleCommonError(error);
                }
                if(finallyCallback){
                    finallyCallback("error");
                }else{
                    me.handleCommonFinally();
                }
            },
            complete: ()=>{
                if(finallyCallback){
                    finallyCallback();
                }else{
                    me.handleCommonFinally();
                }
            }
        })
    }

    public put(url:string, headers:{[key:string]:any}, data: any,params?:{[key:string]:any}, callback?:Function, errorCallback?:Function, finallyCallback?:Function):void{
        let obs = this.httpClient.put(`${this.baseUrl}${url}`,data, {
            headers: {
                ...this.headers,
                ...headers
            },
            params

        });
        let me = this;
        obs.subscribe({
            next: (response)=>{
                if(callback){
                    callback(response);
                }else{
                    me.handleCommonNext(response);
                }
            },
            error: (error)=>{
                if(errorCallback){
                    errorCallback(error);
                }else{
                    me.handleCommonError(error);
                }
                if(finallyCallback){
                    finallyCallback("error");
                }else{
                    me.handleCommonFinally();
                }
            },
            complete: ()=>{
                if(finallyCallback){
                    finallyCallback();
                }else{
                    me.handleCommonFinally();
                }
            }
        })
    }

    public delete(url:string, headers:{[key:string]:any}, params:{[key:string]:any}, callback?:Function, errorCallback?:Function, finallyCallback?:Function):void{
        let obs = this.httpClient.delete(`${this.baseUrl}${url}`, {
            headers: {
                ...this.headers,
                ...headers
            },
            params
        });
        let me = this;
        obs.subscribe({
            next: (response)=>{
                if(callback){
                    callback(response);
                }else{
                    me.handleCommonNext(response);
                }
            },
            error: (error)=>{
                if(errorCallback){
                    errorCallback(error);
                }else{
                    me.handleCommonError(error);
                }
                if(finallyCallback){
                    finallyCallback("error");
                }else{
                    me.handleCommonFinally();
                }
            },
            complete: ()=>{
                if(finallyCallback){
                    finallyCallback();
                }else{
                    me.handleCommonFinally();
                }
            }
        })
    }

    public upload(url: string, objectFile, headers:{[key:string]:any}, params:{[key:string]:any}, callback?:Function, errorCallback?: Function, finallyCallback?: Function){
        let data = new FormData();
        data.append("file", objectFile);
        let obs = this.httpClient.post(`${this.baseUrl}${url}`, data, {
            headers: {
                ...this.headers,
                ...headers
            },
            params
        });
        let me = this;
        obs.subscribe({
            next: (response)=>{
                if(callback){
                    callback(response);
                }else{
                    me.handleCommonNext(response);
                    me.messageCommonService.offload()
                }
            },
            error: (error)=>{
                if(errorCallback){
                    errorCallback(error);
                }else{
                    me.handleCommonError(error);
                    me.messageCommonService.offload()
                }
                if(finallyCallback){
                    finallyCallback("error");
                }else{
                    me.handleCommonFinally();
                    me.messageCommonService.offload()
                }
            },
            complete: ()=>{
                if(finallyCallback){
                    finallyCallback();
                }else{
                    me.handleCommonFinally();
                    me.messageCommonService.offload()
                }
            }
        })
    }

    public uploads(url: string, objectFile, headers:{[key:string]:any}, params:{[key:string]:any}, callback?:Function, errorCallback?: Function, finallyCallback?: Function){
        let data = new FormData();
        for(let file of objectFile){
            data.append("files[]", file);
        }
        let obs = this.httpClient.post(`${this.baseUrl}${url}`, data, {
            headers: {
                ...this.headers,
                ...headers
            },
            params
        });
        let me = this;
        obs.subscribe({
            next: (response)=>{
                if(callback){
                    callback(response);
                }else{
                    me.handleCommonNext(response);
                    me.messageCommonService.offload()
                }
            },
            error: (error)=>{
                if(errorCallback){
                    errorCallback(error);
                }else{
                    me.handleCommonError(error);
                    me.messageCommonService.offload()
                }
                if(finallyCallback){
                    finallyCallback("error");
                }else{
                    me.handleCommonFinally();
                    me.messageCommonService.offload()
                }
            },
            complete: ()=>{
                if(finallyCallback){
                    finallyCallback();
                }else{
                    me.handleCommonFinally();
                    me.messageCommonService.offload()
                }
            }
        })
    }

    public postAndUpload(url:string,objectFile, headers:{[key:string]:any}, data: any,params?:{[key:string]:any}, callback?:Function, errorCallback?:Function, finallyCallback?:Function):void{
        let formData = new FormData();
        if(objectFile){
            formData.append("file", objectFile);
        }
        Object.keys(data).forEach(key => formData.append(key, data[key]));
        let obs = this.httpClient.post(`${this.baseUrl}${url}`, formData, {
            headers: {
                ...this.headers,
                ...headers,
            },
            params
        });
        let me = this;
        obs.subscribe({
            next: (response)=>{
                if(callback){
                    callback(response);
                }else{
                    me.handleCommonNext(response);
                    me.messageCommonService.offload()
                }
            },
            error: (error)=>{
                if(errorCallback){
                    errorCallback(error);
                }else{
                    me.handleCommonError(error);
                    me.messageCommonService.offload()
                }
                if(finallyCallback){
                    finallyCallback("error");
                }else{
                    me.handleCommonFinally();
                    me.messageCommonService.offload()
                }
            },
            complete: ()=>{
                if(finallyCallback){
                    finallyCallback();
                }else{
                    me.handleCommonFinally();
                    me.messageCommonService.offload()
                }
            }
        })
    }

    public putAndUpload(url:string,objectFile, headers:{[key:string]:any}, data: any,params?:{[key:string]:any}, callback?:Function, errorCallback?:Function, finallyCallback?:Function):void{
        let formData = new FormData();
        if(objectFile){
            formData.append("file", objectFile);
        }
        Object.keys(data).forEach(key => formData.append(key, data[key]));
        let obs = this.httpClient.put(`${this.baseUrl}${url}`, formData, {
            headers: {
                ...this.headers,
                ...headers
            },
            params
        });
        let me = this;
        obs.subscribe({
            next: (response)=>{
                if(callback){
                    callback(response);
                }else{
                    me.handleCommonNext(response);
                    me.messageCommonService.offload()
                }
            },
            error: (error)=>{
                if(errorCallback){
                    errorCallback(error);
                }else{
                    me.handleCommonError(error);
                    me.messageCommonService.offload()
                }
                if(finallyCallback){
                    finallyCallback("error");
                }else{
                    me.handleCommonFinally();
                    me.messageCommonService.offload()
                }
            },
            complete: ()=>{
                if(finallyCallback){
                    finallyCallback();
                }else{
                    me.handleCommonFinally();
                    me.messageCommonService.offload()
                }
            }
        })
    }

    public uploadFake(url: string, objectFile, headers:{[key:string]:any}, params:{[key:string]:any}, callback?:Function, errorCallback?: Function, finallyCallback?: Function){
        let data = new FormData();
        data.append("file", objectFile);
        let obs = this.httpClient.post(`${this.baseUrlFake}${url}`, data, {
            headers: {
                ...this.headers,
                ...headers
            },
            params
        });
        let me = this;
        obs.subscribe({
            next: (response)=>{
                if(callback){
                    callback(response);
                }else{
                    me.handleCommonNext(response);
                }
            },
            error: (error)=>{
                if(errorCallback){
                    errorCallback(error);
                }else{
                    me.handleCommonError(error);
                }
                if(finallyCallback){
                    finallyCallback("error");
                }else{
                    me.handleCommonFinally();
                }
            },
            complete: ()=>{
                if(finallyCallback){
                    finallyCallback();
                }else{
                    me.handleCommonFinally();
                }
            }
        })
    }

    public download(url:string, headers:{[key:string]:any}, params:{[key:string]:any}, callback?:Function,errorCallback?: Function, finallyCallback?: Function):void{
        let obs = this.httpClient.get(`${this.baseUrl}${url}`, {
            headers: {
                ...this.headers,
                ...headers,
            },
            responseType: 'blob' as 'json',
            params,
            observe: 'response'
        });
        let me = this;
        obs.subscribe({
            next: (response:any)=>{
                if(callback){
                    let blob = new Blob([response.body], { type: 'application/octet-stream' });
                    callback(blob);
                }else{
                    me.downloadFile(response);
                }
            },
            error: (error)=>{
                me.handleCommonError(error);
                me.handleCommonFinally();
                me.messageCommonService.offload()
            },
            complete: ()=>{
                me.handleCommonFinally();
                if(finallyCallback){
                    finallyCallback();
                }
                me.messageCommonService.offload()
            }
        })
    }

    public downloadPost(url:string, headers:{[key:string]:any}, data:{[key:string]:any}, params:{[key:string]:any}):void{
        let obs = this.httpClient.post(`${this.baseUrl}${url}`, data,{
            headers: {
                ...this.headers,
                ...headers,
            },
            responseType: 'blob' as 'json',
            params,
            observe: 'response'
        });
        let me = this;
        obs.subscribe({
            next: (response)=>{
                me.downloadFile(response);
            },
            error: (error)=>{
                me.handleCommonError(error);
                me.handleCommonFinally();
                me.messageCommonService.offload()
            },
            complete: ()=>{
                me.handleCommonFinally();
                me.messageCommonService.offload()
            }
        })
    }

    public downloadLocal(url:string, filename: string):void{
        let obs = this.httpClient.get(`${url}`, {
            headers: {
                ...this.headers,
            },
            responseType: 'blob' as 'json',
            observe: 'response'
        });
        let me = this;
        obs.subscribe({
            next: (response)=>{
                me.downloadFile(response, filename);
            },
            error: (error)=>{
                me.handleCommonError(error);
                me.handleCommonFinally();
                me.messageCommonService.offload()
            },
            complete: ()=>{
                me.handleCommonFinally();
                me.messageCommonService.offload()
            }
        })
    }

    public downloadFake(url:string, headers:{[key:string]:any}, params:{[key:string]:any}):void{
        let obs = this.httpClient.get(`${this.baseUrlFake}${url}`, {
            headers: {
                ...this.headers,
                ...headers,
            },
            responseType: 'blob' as 'json',
            params,
            observe: 'response'
        });
        let me = this;
        obs.subscribe({
            next: (response)=>{
                me.downloadFile(response);
            },
            error: (error)=>{
                me.handleCommonError(error);
                me.handleCommonFinally();
                me.messageCommonService.offload()
            },
            complete: ()=>{
                me.handleCommonFinally();
                me.messageCommonService.offload()
            }
        })
    }

    private downloadFile(response, filename?:string){
        let contentDisposition = response.headers.get('content-disposition') || 'attachment; filename=default.csv';
        if(!filename){
            filename = contentDisposition.split(";")[1].split("=")[1];
        }
        let blob = new Blob([response.body], { type: 'application/octet-stream' });
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement('a');
        a.href = url;
        a.download = filename;
        a.click();
        a.remove();
        this.messageCommonService.offload()
    }
}
