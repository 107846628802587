import { Injector, OnInit } from '@angular/core';
import { Component } from '@angular/core';
import { LayoutService } from './service/app.layout.service';
import { ComponentBase } from 'src/app/component.base';
import { CONSTANTS } from 'src/app/service/comon/constants';
import { MenuItem } from 'primeng/api';
import { ConnectionService } from 'src/app/service/connect/connect.service';

@Component({
    selector: 'app-menu',
    templateUrl: './app.menu.component.html'
})
export class AppMenuComponent extends ComponentBase implements OnInit {

    model: MenuItem[] = [];
    listTypeConnection: Array<any> = [];

    constructor(public layoutService: LayoutService, private injector: Injector, private connectService: ConnectionService) {
        super(injector);
    }

    ngOnInit() {
        this.model = [];
        this.getListTypeConnection();
    }

    getListTypeConnection(){
        let me = this;
        this.connectService.getListTypeConnection((response)=>{
            me.listTypeConnection = response;
            me.createMenu();
        })
    }

    createMenu(){
        this.model = [
            {
                label: `${this.tranService.translate("global.text.hello")}, ${this.sessionService.userInfo.name}`,
            },
            {
                label: this.tranService.translate('global.menu.managerDashboard'),
                items: [
                    { label: this.tranService.translate('global.menu.dashboard'), icon: 'pi pi-fw pi-chart-line', routerLink: ['/template/me'] },
                ],
                visible: [CONSTANTS.USER_TYPE.CUSTOMER, CONSTANTS.USER_TYPE.TRIAL].includes(this.sessionService.userInfo.type) && this.checkAuthen([CONSTANTS.PERMISSION.DASHBOARD.VIEW])
            },
            {
                label: this.tranService.translate('global.menu.managerAccount'),
                items: [
                    { label: this.tranService.translate('global.menu.listAccount'), icon: 'pi pi-fw pi-user', routerLink: ['/account'], visible: this.checkAuthen([CONSTANTS.PERMISSION.ACCOUNT.SEARCH]) },
                    { label: this.tranService.translate('global.menu.listRole'), icon: 'pi pi-fw pi-user', routerLink: ['/role'] , visible: this.checkAuthen([CONSTANTS.PERMISSION.ROLE.SEARCH])},
                    { label: this.tranService.translate('global.menu.listpermission'), icon: 'pi pi-fw pi-user', routerLink: ['/permission'] , visible: this.checkAuthen([CONSTANTS.PERMISSION.PERMISSIONS.SEARCH])},
                ],
                visible: this.sessionService.userInfo.type == CONSTANTS.USER_TYPE.ADMIN && this.checkAuthen([CONSTANTS.PERMISSION.ACCOUNT.SEARCH, CONSTANTS.PERMISSION.ROLE.SEARCH, CONSTANTS.PERMISSION.PERMISSIONS.SEARCH])
            },
            {
                label: this.tranService.translate('global.menu.managerTemplate'),
                items: [
                    { label: this.tranService.translate('global.menu.listTemplate'), icon: 'pi pi-fw pi-images', routerLink: ['/template'], visible: this.sessionService.userInfo.type == CONSTANTS.USER_TYPE.ADMIN && this.checkAuthen([CONSTANTS.PERMISSION.DASHBOARD.SEARCH]) },
                    { label: this.tranService.translate('global.menu.listTemplate'), icon: 'pi pi-fw pi-images', routerLink: ['/template/list'], visible: [CONSTANTS.USER_TYPE.CUSTOMER, CONSTANTS.USER_TYPE.TRIAL].includes(this.sessionService.userInfo.type) && this.checkAuthen([CONSTANTS.PERMISSION.DASHBOARD.VIEW])},
                ],
                visible: this.checkAuthen([CONSTANTS.PERMISSION.DASHBOARD.SEARCH, CONSTANTS.PERMISSION.DASHBOARD.VIEW])
            },
            { 
                label: this.tranService.translate('global.menu.managerConnectionInformation'),
                items: [
                    { label: this.tranService.translate('global.menu.listTypeInformation'), icon: 'pi pi-fw pi-wifi', routerLink: ['/connection/type-information'], visible: this.sessionService.userInfo.type == CONSTANTS.USER_TYPE.ADMIN && this.checkAuthen([CONSTANTS.PERMISSION.TYPE_INFORMATION.SEARCH])},
                    { label: this.tranService.translate('global.menu.listConnection'), icon: 'pi pi-fw pi-globe', routerLink: ['/connection'], visible: this.checkAuthen([CONSTANTS.PERMISSION.CONNECTION.SEARCH])},
                    { label: this.tranService.translate('global.menu.listFile'), icon: 'pi pi-fw pi-file', routerLink: ['/connection/file'], visible: this.checkAuthen([CONSTANTS.PERMISSION.FILE.SEARCH])},
                    { label: this.tranService.translate('global.menu.createConnection'), icon: 'pi pi-fw pi-list',
                        items: (this.listTypeConnection || []).map(el => {
                            return {
                                label: el.name,
                                url: '/connection/register/'+el.id
                            }
                        }),
                        visible: [CONSTANTS.USER_TYPE.CUSTOMER, CONSTANTS.USER_TYPE.TRIAL].includes(this.sessionService.userInfo.type) && this.checkAuthen([CONSTANTS.PERMISSION.CONNECTION.CREATE])
                    },
                ],
                visible: this.checkAuthen([CONSTANTS.PERMISSION.CONNECTION.SEARCH, CONSTANTS.PERMISSION.TYPE_INFORMATION.SEARCH, CONSTANTS.PERMISSION.FILE.SEARCH, CONSTANTS.PERMISSION.CONNECTION.CREATE])
            },
            // { 
            //     label: this.tranService.translate('global.menu.managerData'),
            //     items: [
            //         { label: this.tranService.translate('global.menu.listDataRaw'), icon: 'pi pi-fw pi-server', routerLink: ['/dataset'], visible: this.checkAuthen([CONSTANTS.PERMISSION.DATA_COLLECT.SEARCH])},
            //     ],
            //     visible: this.checkAuthen([CONSTANTS.PERMISSION.DATA_COLLECT.SEARCH])
            // },
            { 
                label: this.tranService.translate('global.menu.managerLog'),
                items: [
                    { label: this.tranService.translate('global.menu.listLog'), icon: 'pi pi-fw pi-whatsapp', routerLink: ['/logs'], visible: this.checkAuthen([CONSTANTS.PERMISSION.LOG.VIEW])},
                ],
                visible: this.checkAuthen([CONSTANTS.PERMISSION.LOG.VIEW])
            },
            { 
                label: this.tranService.translate('global.menu.guide'),
                items: [
                    { label: this.tranService.translate('global.menu.guide'), icon: 'pi pi-fw pi-map',url: "https://docs.powerdashboard.vn/",
                        target: "_blank",},
                ],
                visible: [CONSTANTS.USER_TYPE.CUSTOMER, CONSTANTS.USER_TYPE.TRIAL].includes(this.sessionService.userInfo.type)
            }
        ];
    }
}
