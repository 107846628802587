import { Inject, Injectable } from "@angular/core";
import { HttpService } from "../comon/http.service";

@Injectable()
export class RolesService{
    private prefixApi: string;
    constructor(@Inject(HttpService) private httpService:HttpService) {
        this.prefixApi = "/roles";
    }

    public demo(url:string, params:{[key: string]: string}, callback?:Function, errorCallBack?:Function, finallyCallback?:Function){
        this.httpService.get(url,{}, params,callback, errorCallBack, finallyCallback);
    }

    public search(params:{[key:string]:any}, callback?:Function, errorCallBack?:Function, finallyCallback?:Function){
        this.httpService.get(`${this.prefixApi}/search`,{}, params,callback, errorCallBack, finallyCallback);
    }
    public getById(id:number, callback?:Function, errorCallBack?:Function, finallyCallback?:Function){
        this.httpService.get(`${this.prefixApi}/${id}`,{}, {},callback, errorCallBack, finallyCallback);
    }
    public deleteRole(id:string, callback?:Function, errorCallBack?:Function, finallyCallback?:Function){
        this.httpService.delete(`${this.prefixApi}/${id}`,{}, {},callback, errorCallBack, finallyCallback);
    }
    public editRole(id:number, role:{name?: string, description?:string, type?:number, status?:number, permissionIds?:Array<number>}, callback?:Function, errorCallBack?:Function, finallyCallback?:Function){
        this.httpService.put(`${this.prefixApi}/${id}`,{}, role,{}, callback, errorCallBack, finallyCallback);
    }
    public createRole(role:{name?: string, description?:string, type?:number, status?:number, permissionIds?:Array<number>}, callback?:Function, errorCallBack?:Function, finallyCallback?:Function){
        this.httpService.post(`${this.prefixApi}`,{}, role, {}, callback, errorCallBack, finallyCallback);
    }
    public checkName(query:{[key:string]:any}, callback?:Function, errorCallBack?:Function, finallyCallback?:Function){
        this.httpService.get(`${this.prefixApi}/checkExist`,{}, query,callback, errorCallBack, finallyCallback);
    }
    public changeStatusRole(id:number, callback?:Function, errorCallBack?:Function, finallyCallback?:Function){
        this.httpService.put(`${this.prefixApi}/update-status/${id}`,{}, {}, {},callback, errorCallBack, finallyCallback);
    }
}
