export default class DataPage{
    constructor(pageTitle?:string, authorities?: Array<string>) {
        if(pageTitle){
            this.pageTitle = pageTitle;
        }
        if(authorities){
            this.authorities = authorities;
        }
    }
    pageTitle?: string = "Dashboard Managment";
    authorities?: Array<string> = [];
}