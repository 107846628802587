import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { AppLayoutComponent } from "./template/layout/app.layout.component";
import { AppAccountProfileComponent } from './template/account/profile/app.account.profile.component';
import { AppChangePasswordComponent } from './template/account/change-password/app.change.password';
import { AppConnectionDataRawComponent } from './template/connect/data-raw/app.connection.data.raw.component';
import DataPage from './service/data.page';
import { CONSTANTS } from './service/comon/constants';

@NgModule({
    imports: [
        RouterModule.forRoot([
            {
                path: '', component: AppLayoutComponent,
                children: [
                    {path: "template", loadChildren: () => import('./template/template-dashboard/app.template.module').then(m => m.AppProjectModule)},
                    {path: "account", loadChildren: () => import('./template/account/app.account.module').then(m => m.AppStudentModule)},
                    { path: 'dashboard', loadChildren: () => import('./template/dashboard/app.dashboard.module').then(m => m.AppDashboardModule) },
                    { path: 'connection', loadChildren: () => import('./template/connect/app.connect.module').then(m => m.AppConnectModule  ) },
                    { path: 'permission', loadChildren: () => import('./template/permission/app.permission.module').then(m => m.PermissionListModule  ) },
                    { path: 'role', loadChildren: () => import('./template/roles/app.roles.module').then(m => m.AppRolesModule  ) },
                    { path: 'dataset', component: AppConnectionDataRawComponent, data: new DataPage("global.menu.listDataRaw", [CONSTANTS.PERMISSION.DATA_COLLECT.SEARCH])},
                    {path: "profile", component: AppAccountProfileComponent},
                    {path: "change-password", component: AppChangePasswordComponent},
                    {path: "logs", loadChildren: () => import('./template/logs/app.roles.module').then(m => m.AppRolesModule)}
                ]
            },
            { path: 'error', loadChildren: () => import('./template/pages/error/error.module').then(m => m.ErrorModule) },
            { path: 'access', loadChildren: () => import('./template/pages/access/access.module').then(m => m.AccessModule) },
            { path: 'login', loadChildren: () => import('./template/pages/login/login.module').then(m => m.LoginModule) },
            { path: 'dashboard/:templateId', loadChildren: () => import('./template/pages/dashboard-detail/dashboard-detail.module').then(m => m.DashboardDetailModule) },
            { path: 'reset-password', loadChildren: () => import('./template/pages/reset-password/reset-password.module').then(m => m.ResetPasswordModule) },
            { path: 'notfound', loadChildren: () => import('./template/pages/notfound/notfound.module').then(m => m.NotfoundModule) },
            { path: '**', redirectTo: '/notfound' },
        ], { scrollPositionRestoration: 'enabled', anchorScrolling: 'enabled', onSameUrlNavigation: 'reload' })
    ],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
