export default {
    label: {
        name: "Tên dashboard ",
        state: "Trạng thái",
        linkDemo: "Link Demo",
        imageDemo: "Image Demo",
        description: "Mô tả",
        summary: "Mô tả ngắn",
        describe: "Mô tả",
        descriptionDashboard: "Mô tả dashboard",
        listConnectionRequired: "Danh sách connection cần có",
        demo: "Demo",
        guideExtension: "Hướng dẫn sử dụng extension",
        connectionsRequired: "Kết nối yêu cầu",
        extensionRequired: "Yêu cầu extension",
        sourceFile: "Source File",
        tab1: "Danh sách các báo cáo",
        tab2: "Danh sách các chỉ số",
        tab3: "Các kết nối cần có",
        tab4: "Hướng dẫn sử dụng extension",
        suggestTag: "Gợi ý thẻ",
        tag: "Thẻ",
        dashboard: "Dashboard",
        viewDashboard: "Xem Dashboard"
    },
    text: {
        inputDashboardName: "Nhập tên Dashboard mẫu",
        inputLinkDemo: "Nhập tên link demo",
        inputSummary: "Nhập mô tả ngắn",
        inputConnectionsRequired: "Chọn các kết nối được yêu cầu",
        applyTemplate: "Áp dụng mẫu dashboard",
        templateApplied: "Mẫu dashboard này đã được áp dụng",
        usedAnotherTemplage: "Đã dùng một mẫu khác. Vui lòng bỏ mẫu trước đó!",
        noConnection: "Bạn chưa khai báo các kết nối cần có, vui lòng xem tab \"Các kết nối cần có\" và thực hiện khai báo connection @@links0",
        noExtension: "Bạn chưa khai báo extension, vui lòng xem tab Hướng dẫn sử dụng extension và thực hiện khai báo extension theo hướng dẫn",
        noPermission: "Bạn chưa có quyền sử dụng dashboard này. Vui lòng chọn dashboard khác hoặc liên hệ hotline \"039 289 6286\" để nâng cấp tài khoản có quyền sử dụng dashboard này!",
        inputTag: "Vui lòng nhập tag theo mẫu \"tag(display)\"",
        selectSuggestTag: "Chọn tag có sẵn",
        waitApplyNotRegister: "Hệ thống đang khởi tạo dashboard cho bạn. Bạn vui lòng đợi ít phút!",
        waitApplyRegister: "Hệ thống đang khởi tạo dashboard cho bạn. Bạn vui lòng đợi ít phút sau đó vào menu @@links0 để sử dụng dashboard",
        connectionSync: "Do kết nối ${connectionName} - ${typeName} đang trong tiến trình đồng bộ dữ liệu. Nên dữ liệu trên dashboard hiện tại chưa phải dữ liệu mới nhất. Bạn vui lòng quay lại xem dashboard sau ít phút nữa để được cập nhật dữ liệu mới nhất."
    },
    userTemplate: {
        accessToken: "Access Token",
        accessTokenId: "Access Token Id",
        dataSetId: "Data Set Id",
        dataSourceId: "Datasource Id",
        displayName: "Display Name",
        embedUrl: "Embed Url",
        expireAt: "Expired At",
        gatewayId: "Gateway Id",
        groupId: "Group Id",
        reportId: "Report Id"
    }
}