import { Component, Inject, Injectable, Injector, OnInit, OnDestroy } from "@angular/core";
import { ActivatedRoute, NavigationEnd, Router, RouterModule } from "@angular/router";
import DataPage from "./service/data.page";
import { TranslateService } from "./service/comon/translate.service";
import { UtilService } from "./service/comon/util.service";
import { SessionService } from "./service/session/SessionService";
import { MessageCommonService } from "./service/comon/message-common.service";
import { DebounceInputService } from "./service/comon/debounce.input.service";
import { RouterService } from "./service/comon/router.service";
import { ObservableService } from "./service/comon/observable.service";
import { CONSTANTS } from "./service/comon/constants";

@Component({
    template: ''
})
export abstract class ComponentBase implements OnDestroy{
    public tranService: TranslateService;
    public utilService: UtilService;
    protected messageCommonService: MessageCommonService;
    protected sessionService: SessionService;
    protected debounceService: DebounceInputService;
    protected router: Router;
    protected route: ActivatedRoute;
    protected routerService: RouterService;
    protected observableService: ObservableService;

    pathNotCheckPolicy: Array<string> = ["/error", "/access", "/login", "/reset-password", "/notfound", "/landing"];
    pathNotChechAuthen: Array<string> = ["/error", "/access", "/login", "/reset-password", "/notfound", "/landing"];
    pathNotCheckLogin: Array<string> = ["/login", "/reset-password","/landing"];

    constructor(injector: Injector) {
        this.router = injector.get(Router);
        this.route = injector.get(ActivatedRoute);
        this.tranService = injector.get(TranslateService);
        this.utilService = injector.get(UtilService);
        this.messageCommonService = injector.get(MessageCommonService);
        this.sessionService = injector.get(SessionService);
        this.debounceService = injector.get(DebounceInputService);
        this.routerService = injector.get(RouterService);
        this.observableService = injector.get(ObservableService);
        let me = this;
        this.router.events.subscribe((event:NavigationEnd)=>{
            if(!event.url) return;
            if(this.pathNotCheckLogin.includes(event.url)) return;
            if(!localStorage.getItem("token")){
                window.location.href = '/login';
            }
            if(this.pathNotCheckPolicy.includes(event.url)) return;
            // console.log(event.url, me.router.url, event.url == me.router.url)
            if(event.url == me.router.url){
                me.executeChangeRouting(event);
            }
        })
    }

    private executeChangeRouting(event:NavigationEnd){
        let dataPage: DataPage = this.routerService.getDataPage();
        if(dataPage == null || dataPage == undefined) return;
        let title: string = dataPage.pageTitle;
        if((title || "").length > 0){
            document.title = this.tranService.translate(title);
        }else{
            document.title = "Dashboard Managment";
        }
        if(this.pathNotChechAuthen.includes(event.url)) return;
        let result = this.checkAuthen(dataPage.authorities);
        if(!result){
            window.location.href = "/access";
        }
    }

    public checkAuthen(authens: Array<string>, isReportDynamic: boolean = false):boolean{
        if((authens || []).length == 0) return true;
        if(this.sessionService.userInfo.authorities == undefined){
            window.location.href = '/login';
        }
        try {
            let userAuthens = this.sessionService.userInfo.authorities;
            if((userAuthens || []).length == 0){
                return false;
            }
            let hasViewContentReportDynamic = false;
            if((authens || []).length > 0){
                let flag = false;
                for(let i = 0; i < authens.length;i++){
                    if(userAuthens.includes(authens[i])){
                        flag = true;
                        break;
                    }
                }
                if(!flag){
                    return false;
                }
            }
            return true;
        } catch (error) {
            window.location.href = '/login';
            return false;
        }
    }

    ngOnDestroy(): void {
        this.debounceService.clear()
    }
}