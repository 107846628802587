export default {
    label: {
        action: "Hành động",
        createTime: "Thời gian tạo",
        state: "Trạng thái",
        errorDescription: "Mô tả lỗi",
        childAction: "Hành động con",
        information: "Thông tin",
        transaction: "Giao dịch"
    }
}