<div class="qlda flex flex-row justify-content-between align-items-center bg-white p-2 border-round">
    <div class="">
        <div class="text-xl font-bold mb-1">{{this.tranService.translate("global.menu.listConnection")}}</div>
        <p-breadcrumb class="max-w-full col-7" [model]="items" [home]="home"></p-breadcrumb>
    </div>
    <div class="col-5 flex flex-row justify-content-end align-items-center">
        
    </div>
</div>

<form [formGroup]="formSearch" (ngSubmit)="onSubmitSearch()" class="pb-2 pt-3 qlda-field-set">
    <p-panel [toggleable]="true" [header]="tranService.translate('global.text.filter')">
        <div class="grid">
            <!-- type connection -->
            <div class="col-3" class="hidden">
                <span class="p-float-label">
                    <p-dropdown styleClass="w-full" [showClear]="true"
                            id="typeConnection" [autoDisplayFirst]="false"
                            [(ngModel)]="searchInfo.typeConnection" 
                            formControlName="typeConnection"
                            [options]="listTypeConnection"
                            optionLabel="name"
                            optionValue="id"
                            (ngModelChange)="getListTypeInformation()"
                    ></p-dropdown>
                    <label htmlFor="typeConnection">{{tranService.translate("connect.label.typeConnection")}}</label>
                </span>
            </div>
            <!-- type information -->
            <div class="col-3">
                <span class="p-float-label">
                    <p-dropdown styleClass="w-full" [showClear]="true"
                            id="typeInformation" [autoDisplayFirst]="false"
                            [(ngModel)]="searchInfo.typeInformation" 
                            formControlName="typeInformation"
                            [options]="listTypeInformation"
                            optionLabel="name"
                            optionValue="id"
                    ></p-dropdown>
                    <label for="typeInformation">{{tranService.translate("connect.label.typeInformation")}}</label>
                </span>
            </div>
            <!-- userId -->
            <div class="col-3" *ngIf="isAdmin">
                <div class="relative">
                    <custom-select
                        class="w-full"
                        [(value)]="searchInfo.userId"
                        [placeholder]="tranService.translate('account.label.account')"
                        objectKey="account"
                        paramKey="name"
                        keyReturn="id"
                        displayPattern="${name}"
                        typeValue="primitive"
                        [floatLabel]="true"
                        [isMultiChoice]="false"
                    ></custom-select>
                </div>
            </div>
            <div class="col-3 pb-0">
                <p-button icon="pi pi-search" 
                            styleClass="p-button-rounded p-button-secondary p-button-text button-search"
                            type="submit"
                ></p-button>
            </div>
        </div>
    </p-panel>
</form>

<table-qlda 
    [fieldId]="'id'"
    [(selectItems)]="selectItems" 
    [columns]="columns" 
    [dataSet]="dataSet" 
    [options]="optionTable"
    [loadData]="search.bind(this)"
    [pageNumber]="pageNumber"
    [pageSize]="pageSize"
    [sort]="sort"
    [params]="searchInfo"
    [labelTable]="this.tranService.translate('global.menu.listDataRaw')"
></table-qlda>