import { Component, ElementRef, Injector, OnInit } from "@angular/core";
import { MenuItem } from "primeng/api";
import { ComponentBase } from "src/app/component.base";
import { FormBuilder } from "@angular/forms";
import { AccountService } from "src/app/service/account/AccountService";
import { CONSTANTS } from "src/app/service/comon/constants";

@Component({
    selector: 'app-account-profile',
    templateUrl: './app.account.profile.component.html'
})
export class AppAccountProfileComponent extends ComponentBase implements OnInit{

    items: MenuItem[];
    home: MenuItem;

    account: {
        id: number | null,
        name: string | null,
        email: string | null,
        phone: string | null,
        active: number | null
    } = {
        id: null,
        name: null,
        email: null,
        phone: null,
        active: 0
    }
    accountOld: any;

    accountForm: any;
    isEmailExisted: boolean = false;
    isPhoneExisted: boolean = false;

    userId: number;

    constructor(private accountService: AccountService,
                private eRef: ElementRef,
                private formBuilder: FormBuilder,
                private injector: Injector) {
        super(injector);
    }

    ngOnInit(): void {
        let me = this;
        this.items = [{ label: this.tranService.translate("global.text.accountInfo")}];
        this.home = { icon: 'pi pi-home', routerLink: '/' };

        this.userId = this.sessionService.userInfo.id;
        this.getOne();
    }

    getOne(){
        let me = this;
        this.accountService.getById(this.userId, (response)=>{
            me.account = response;
            me.accountOld = {...response};
            let dataForm = {
                id: response.id,
                name: response.name,
                email: response.email,
                phone: response.phone,
                active: response.active
            }
            me.accountForm = me.formBuilder.group(dataForm);
            me.accountForm.controls.email.disable();
        })
    }

    onSubmitCreate(){
        let me = this;
        me.messageCommonService.onload();
        me.accountService.updateProfile(this.userId, this.account, (response)=>{
            me.messageCommonService.success(me.tranService.translate('global.message.success'));
        }, null, ()=> me.messageCommonService.offload())
    }

    checkExistAccount(key){
        let me = this;
        if(this.account[key] && this.account[key] != this.accountOld[key]){
            this.debounceService.set("checkAccount"+key, () => {
                me.accountService.checkAccount(key, this.account[key], (response)=>{
                    if(key == "email"){
                        if(response > 0){
                            me.isEmailExisted = true;
                        }else{
                            me.isEmailExisted = false;
                        }
                    }else if(key == "phone"){
                        if(response > 0){
                            me.isPhoneExisted = true;
                        }else{
                            me.isPhoneExisted = false;
                        }
                    }
                })
            })
        }
    }

    getTextState(status){
        if(status == CONSTANTS.ACTIVE){
            return this.tranService.translate('global.text.active')
        }else if(status == CONSTANTS.INACTIVE){
            return this.tranService.translate('global.text.inactive')
        }else {
            return '';
        }
    }
}