export default {
    menu: {
        home: "Trang chủ",
        managerDashboard: "Quản lý Dashboard",
        dashboard: "Danh sách Dashboard",
        managerTemplate: "Quản lý Dashboard mẫu",
        listTemplate: "Danh sách Dashboard mẫu",
        listRegisterTemplate: "Danh sách đăng ký",
        listAccount: "Danh sách tài khoản",
        createTemplate: "Tạo mới dashboard",
        editTemplate: "Cập nhật dashboard",
        updateTemplate: "Cập nhật dashboard",
        detailTemplate: "Chi tiết dashboard",
        createAccount: "Tạo mới tài khoản",
        editAccount: "Cập nhật tài khoản",
        updateAccount: "Cập nhật tài khoản",
        detailAccount: "Chi tiết tài khoản",
        managerConnectionInformation: "Quản lý kết nối",
        managerAccount: "Quản lý tài khoản",
        listTypeInformation: "Danh sách kênh kết nối",
        createTypeInformation: "Tạo kênh kết nối",
        updateTypeInformation: "Cập nhật kênh kết nối",
        detailTypeInformation: "Chi tiết kênh kết nối",
        listConnection: "Danh sách kết nối",
        createConnection: "Tạo kết nối",
        updateConnection: "Cập nhật kết nối",
        detailConnection: "Chi tiết kết nối",
        listFile: "Danh sách file",
        managerData: "Quản lý dữ liệu",
        listDataRaw: "Dữ liệu thu thập",
        guide: "Hướng dẫn sử dụng",
        listpermission: "Danh sách quyền",
        listRole: "Danh sách nhóm quyền",
        managerLog: "Quản lý log",
        listLog: "Danh sách log"
    },
    button: {
        edit: "Cập nhật",
        delete: "Xóa",
        save: "Lưu",
        cancel: "Hủy",
        close: "Đóng",
        create: "Tạo mới",
        uploadFile: "Upload file",
        download: "Tải về",
        no: "Không",
        yes: "Có",
        register: "Đăng ký",
        active: "Mở",
        inactive: "Đóng",
        submit: "Nộp bài",
        submitAgain: "Nộp bài lại",
        view: "Chi tiết",
        clear: "Xóa tất cả",
        changePass: "Đổi mật khẩu",
        removeRegister: "Bỏ đăng ký",
        lock: "Khóa",
        unlock: "Mở khóa",
        addParam: "Thêm tham số",
        moveUp: "Di chuyển lên",
        moveDown: "Di chuyển xuống",
        chooseTemplate: "Dùng mẫu này",
        removeTemplate: "Bỏ dùng mẫu này",
        viewDemo: "Xem demo",
        checkConnect: "Kết nối shop của bạn",
        reCheckConnect: "Kết nối lại shop của bạn",
        back: "Quay lại",
        continue: "Tiếp tục",
        synchroniseData: "Đồng bộ dữ liệu ngay",
        viewFile: "Xem file",
        backToListConnection: "Quay lại danh sách kết nối",
        chooseTemplate2: "Chọn Dashboard mẫu",
        changeStatus: "Đổi trạng thái",
        rebuild: "Build lại",
        refresh: "Làm mới",
        preview: "Xem trước"
    },
    text: {
        all: "Tất cả",
        textCaptcha: "Kéo sang để hoàn thành câu đố",
        filter: "Bộ lọc",
        stt: "STT",
        action: "Thao tác",
        nodata: "Không có dữ liệu",
        templateTextPagination: "Hiển thị từ {first} đến {last} của {totalRecords} bản ghi",
        itemselected: "Số phần tử đã chọn",
        page: "Trang",
        accountInfo: "Thông tin tài khoản",
        changePass: "Đổi mật khẩu",
        homepage: "Trang chủ",
        hello: "@Xin chào",
        active: "Hoạt động",
        inactive: "Không hoạt động",
        connected: "Đã kết nối",
        disconnected: "Không kết nối",
        progressingSynchorize: "Đang thực hiện đồng bộ",
        yetSynchronized: "Chưa đồng bộ",  
        synchronized: "Đã đồng bộ",
        inputText: "Nhập chuỗi",
        inputNumber: "Nhập số",
        inputDate: "Nhập thời gian"
    },
    message:{
        copied: "Đã sao chép",
        required: "Trường này là bắt buộc",
        requiredField: "${field} là bắt buộc",
        maxLength: "Trường này không được vượt quá ${len} ký tự",
        minLength: "Trường này không được ít hơn ${len} ký tự",
        max: "Trường này có giá trị tối đa là ${value}",
        min: "Trường này có giá trị tối thiểu là ${value}",
        duplicated: "Dữ liệu đã tồn tại",
        invalidValue: "Dữ liệu không đúng định dạng",
        formatContainVN: "Sai định dạng. Chỉ cho phép (a-z, A-Z, 0-9, . - _, dấu cách, tiếng Viết)",
        formatCode: "Sai định dạng. Chỉ cho phép (a-z, A-Z, 0-9, - _)",
        formatCodeNotSub: "Sai định dạng. Chỉ cho phép (a-z, A-Z, 0-9, _)",
        invalidEmail: "Email sai định dạng",
        formatEmail: "Định dạng email phải là abc@xyz.abc",
        invalidPhone: "Số điện thoại sai định dạng",
        exists: "Đã tồn tại ${type} này",
        success: "Thao tác thành công",
        successAndWaiting: "Thao tác thành công. Xin vui lòng chờ trong ít phút.",
        connectionSuccess: "Kết nối thành công",
        error: "Thao tác thất bại",
        saveSuccess: "Lưu thành công",
        saveError: "Lưu thất bại",
        timeout: "Thao tác quá thời gian",
        statusSuccess: "Thành công",
        statusError: "Thất bại",
        errorMatchCaptcha: "Đặt miếng ghép vào đúng vị trí của nó",
        confirmDeleteAccount: "Bạn có chắc chắc muốn xóa tài khoản này không?",
        titleConfirmDeleteAccount: "Xóa tài khoản",
        deleteSuccess: "Xóa thành công",
        deleteFail: "Xóa thất bại",
        invalidFile: "Định dạng file không hợp lệ",
        invalidPasswordFomat : "Mật khẩu 6-20 ký tự, bao gồm ít nhất 1 chữ cái và 1 số và 1 ký tự đặc biệt",
        passwordNotMatch: "Mật khẩu không khớp",
        wrongCurrentPassword : "Mật khẩu hiện tại sai!",
        forgotPassSendMailSuccess : "Mật khẩu khôi phục đã được gửi tới email của bạn. Xin vui lòng kiểm tra email!.",
        waitForAsync: "Dữ liệu đang được đồng bộ, vui lòng chờ ít phút",
        emailOrPhone: "Vui lòng nhập email hoặc số điện thoại",
        yetInitStorage: "Kho lưu trữ đang khởi tạo. Xin vui lòng liên hệ quản trị viên và đợi chờ trong giây lát!",

        confirmChangeInactiveAccount: "Bạn có chắc chắc muốn khóa tài khoản này không?",
        titleChangeInactiveAccount: "Khóa tài khoản",
        confirmChangeActiveAccount: "Bạn có chắc chắc muốn mở khóa tài khoản này không?",
        titleChangeActiveAccount: "Mở khóa tài khoản",

        titleChangeActiveTemplate: "Mở khóa Dashboard mẫu",
        confirmChangeActiveTemplate: "Bạn có chắc chắc muốn mở khóa Dashboard mẫu này không?",
        titleChangeInactiveTemplate: "Khóa Dashboard mẫu",
        confirmChangeInactiveTemplate: "Bạn có chắc chắc muốn khóa Dashboard mẫu này không?",
        titleDeleteTemplate: "Xóa Dashboard mẫu",
        confirmDeleteTemplate: "Bạn có chắc chắc muốn xóa Dashboard mẫu này không?",
        
        titleDeleteTypeInformation: "Xóa kênh kết nối",
        confirmDeleteTypeInformation: "Bạn có chắc chắc muốn xóa kênh kết nối này không?",
        titleChangeStatusTypeInformation: "Chuyển trạng thái kênh kết nối",
        confirmChangeStatusTypeInformation: "Bạn có chắc chắc muốn chuyển trạng thái kênh kết nối này không?",

        titleDeleteConnection: "Xóa thông tin kết nối",
        confirmDeleteConnection: "Bạn có chắc chắc muốn xóa thông tin kết nối này không?",

        titleDeleteFile: "Xóa file",
        confirmDeleteFile: "Bạn có chắc chắc muốn xóa file này không?",

        titleRemoveTemplate: "Bỏ dùng template",
        confirmRemoveTemplate: "Bạn có chắc chắc muốn bỏ dùng template này không?",

        waitEmbedTemplate: "Dashboard đang được khởi tạo, bạn vui lòng chờ thêm giây lát",
        templateEmpty: "Bạn chưa chọn Dashboard mẫu",

        confirmDeleteRoles: "Bạn có chắc muốn xóa nhóm quyền này không",
        titleConfirmDeleteRoles: "Xóa nhóm quyền",
        confirmChangeStatusRole: "Bạn có chắc muốn đổi trạng thái nhóm quyền này?",
        titleConfirmChangeStatusRole: "Đổi trạng thái nhóm quyền",

        titleRebuildAllDashboardForUser: "Dựng lại tất cả dashboard",
        confirmRebuildAllDashboardForUser: "Bạn có chắc muốn dựng lại tất cả dashboard cho tài khoản này?"
    },
    param: {
        paramList: "Danh sách tham số",
        paramKey: "Trường tham số",
        inputParamKey: "Nhập trường tham số",
        paramType: "Kiểu dữ liệu",
        selectParamType: "Chọn kiểu dữ liệu",
        paramDisplay: "Tên hiển thị",
        inputDisplayName: "Nhập tên hiển thị",
        required: "Bắt buộc",
        dateType: "Kiểu hiển thị ngày",
        selectDateType: "Chọn kiểu hiển thị ngày",
        isMultiChoice: "Lựa chọn nhiều",
        valueDisplay: "Giá trị hiển thị",
        valueDB: "Giá trị truyền",
        createParameter: "Tạo tham số",
        updateParameter: "Sửa tham số",
        detailParameter: "Chi tiết tham số",
        hidden: "Ẩn hiển thị",
        defaultValue: "Giá trị mặc định",
        inputDefaultValue: "Nhập giá trị mặc định",
    },
    paramType: {
        number: "Number",
        string: "String",
        date: "Date",
        listNumber: "Enum Number",
        listString: "Enum String",
        timestamp: "Timestamp",
        boolean: "Boolean",
        longString: "Long String"
    },
    datetype: {
        month: "Tháng",
        date: "Ngày",
        datetime: "Ngày giờ"
    },
    titlepage: {
        createRole: "Tạo nhóm quyền",
        editrole: "Cập nhật nhóm quyền",
        detailrole: "Chi tiết nhóm quyền"
    }
}
