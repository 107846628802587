<div class="qlda flex flex-row justify-content-between align-items-center bg-white p-2 border-round">
    <div class="">
        <div class="text-xl font-bold mb-1">{{this.tranService.translate("global.text.accountInfo")}}</div>
        <p-breadcrumb class="max-w-full col-7" [model]="items" [home]="home"></p-breadcrumb>
    </div>
    <div class="col-5 flex flex-row justify-content-end align-items-center">
        
    </div>
</div>

<p-card styleClass="mt-3">
    <div *ngIf="accountForm">
        <form [formGroup]="accountForm" (ngSubmit)="onSubmitCreate()">
            <div class="flex flex-row justify-content-between">
                <div style="width: 69%;">
                    <!-- name -->
                    <div class="w-full field grid">
                        <label htmlFor="accountName" class="col-fixed" style="width:180px">{{tranService.translate("account.label.name")}}<span class="text-red-500">*</span></label>
                        <div class="col">
                            <input class="w-full"
                                    pInputText id="accountName"
                                    [(ngModel)]="account.name"
                                    formControlName="name"
                                    pattern="^[a-zA-Z0-9 _\u00C0-\u1EF9]+$"
                                    [required]="true"
                                    [maxLength]="255"
                                    [placeholder]="tranService.translate('account.text.inputCustomerName')"
                            />
                        </div>
                    </div>
                    <!-- error customer name -->
                    <div class="w-full field grid text-error-field">
                        <label htmlFor="projectName" class="col-fixed" style="width:180px"></label>
                        <div class="col">
                            <small class="text-red-500" *ngIf="accountForm.controls.name.dirty && accountForm.controls.name.errors?.required">{{tranService.translate("global.message.required")}}</small>
                            <small class="text-red-500" *ngIf="accountForm.controls.name.errors?.maxLength">{{tranService.translate("global.message.maxLength",{len:255})}}</small>
                            <small class="text-red-500" *ngIf="accountForm.controls.name.errors?.pattern">{{tranService.translate("global.message.formatContainVN")}}</small>
                        </div>
                    </div>
                    <!-- email -->
                    <div class="w-full field grid">
                        <label htmlFor="email" class="col-fixed" style="width:180px">{{tranService.translate("account.label.email")}}<span class="text-red-500">*</span></label>
                        <div class="col">
                            <input class="w-full"
                                    pInputText id="email"
                                    [(ngModel)]="account.email"
                                    formControlName="email"
                                    [required]="true"
                                    [maxLength]="255"
                                    pattern="^[a-z0-9]+[a-z0-9\-\._]*[a-z0-9]+@([a-z0-9]+[a-z0-9\-\._]*[a-z0-9]+)+(\.[a-z]{2,})$"
                                    [placeholder]="tranService.translate('account.text.inputEmail')"
                                    (ngModelChange)="checkExistAccount('email')"
                            />
                        </div>
                    </div>
                    <!-- error email -->
                    <div class="w-full field grid text-error-field">
                        <label htmlFor="email" class="col-fixed" style="width:180px"></label>
                        <div class="col">
                            <small class="text-red-500" *ngIf="accountForm.controls.email.dirty && accountForm.controls.email.errors?.required">{{tranService.translate("global.message.required")}}</small>
                            <small class="text-red-500" *ngIf="accountForm.controls.email.errors?.maxLength">{{tranService.translate("global.message.maxLength",{len:255})}}</small>
                            <small class="text-red-500" *ngIf="accountForm.controls.email.errors?.pattern">{{tranService.translate("global.message.invalidEmail")}}</small>
                            <small class="text-red-500" *ngIf="isEmailExisted">{{tranService.translate("global.message.exists",{type: tranService.translate("account.label.email").toLowerCase()})}}</small>
                        </div>
                    </div>
                    <!-- phone -->
                    <div class="w-full field grid">
                        <label htmlFor="phone" class="col-fixed" style="width:180px">{{tranService.translate("account.label.phone")}}<span class="text-red-500">*</span></label>
                        <div class="col">
                            <input class="w-full"
                                    pInputText id="phone"
                                    [(ngModel)]="account.phone"
                                    formControlName="phone"
                                    [required]="true"
                                    pattern="^((\+?[1-9][0-9])|0?)[1-9][0-9]{8,9}$"
                                    [placeholder]="tranService.translate('account.text.inputPhone')"
                                    (ngModelChange)="checkExistAccount('phone')"
                            />
                        </div>
                    </div>
                    <!-- error phone -->
                    <div class="w-full field grid text-error-field">
                        <label htmlFor="phone" class="col-fixed" style="width:180px"></label>
                        <div class="col">
                            <small class="text-red-500" *ngIf="accountForm.controls.phone.dirty && accountForm.controls.phone.errors?.required">{{tranService.translate("global.message.required")}}</small>
                            <small class="text-red-500" *ngIf="accountForm.controls.phone.errors?.pattern">{{tranService.translate("global.message.invalidPhone")}}</small>
                            <small class="text-red-500" *ngIf="isPhoneExisted">{{tranService.translate("global.message.exists",{type: tranService.translate("account.label.phone").toLowerCase()})}}</small>
                        </div>
                    </div>
                    <!-- active -->
                    <div class="w-full field grid">
                        <label for="khoaHocIds" class="col-fixed" style="width:180px">{{tranService.translate("account.label.active")}}<span class="text-red-500">*</span></label>
                        <div class="col">
                            <span>{{getTextState(account.active)}}</span>
                        </div>
                    </div>
                </div>
                <div style="width: 29%;">
                    
                </div>
            </div>
            <div class="flex flex-row justify-content-center align-items-center">
                <p-button [label]="tranService.translate('global.button.cancel')" styleClass="p-button-secondary p-button-outlined mr-2" [routerLink]="['/template/me']" routerLinkActive="router-link-active" ></p-button>
                <p-button [label]="tranService.translate('global.button.save')" styleClass="p-button-info" type="submit"
                    [disabled]="accountForm.invalid || isEmailExisted || isPhoneExisted"></p-button>
            </div>
        </form>
    </div>
</p-card>